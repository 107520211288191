import React, { FC } from 'react';
import { Calendar } from 'react-feather';
import Tag from '../tags/Tag';

type DateType = {
  date: string;
  icon?: boolean;
};

const DateFormatted: FC<DateType> = ({ date, icon }) => {
  let dateFormated = new Date(date).toLocaleDateString('nl-be', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  let ico = icon ? <Calendar size={14} /> : '';

  return <Tag label={dateFormated} icon={ico} style="nuetral" lowercase={1} />;
};

export default DateFormatted;
