import React from 'react';
import TextHeader from '../../components/headers/TextHeader';
import TicketForm from '../tickets/TicketForm';

const CreateTicketContainer = () => {
  return (
    <div>
      <TextHeader title={'Ticket aanmaken'} />
      <TicketForm />
    </div>
  );
};

export default CreateTicketContainer;
