import { DatePicker } from 'antd';
import React, { FC } from 'react';

import type { DatePickerProps } from 'antd';

type Props = {
  onChange: (date: Date) => void;
  value: Date;
  startDate?: string;
  endDate?: string;
};

const DateInput: FC<Props> = ({ onChange, value, startDate, endDate }) => {
  const start: any = startDate ? new Date(startDate) : new Date();
  const end: any = endDate ? new Date(endDate) : null;
  const disableDate: DatePickerProps['disabledDate'] = (
    current: any,
    { from }
  ) => {
    return current / 1000 < Math.floor(start / 1000) - 1 * 24 * 60 * 60 || end
      ? current / 1000 > Math.floor(end / 1000) - 1 * 24 * 60 * 60
      : false ||
          new Date(current).getDay() === 0 ||
          new Date(current).getDay() === 6;
  };

  return (
    <DatePicker
      format="DD/MM/YY"
      placeholder="Startdatum"
      defaultValue={value}
      disabledDate={disableDate}
      style={{ display: 'block' }}
      required={true}
      onChange={onChange}
    />
  );
};

export default DateInput;
