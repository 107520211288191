import React, { FC } from 'react';
import { CheckCircle, X } from 'react-feather';

type Number = {
  check: number;
  size?: number;
};

const Checkmark: FC<Number> = ({ check, size = 18 }) => {
  let icon: any = check == 1 ? <CheckCircle size={size} /> : <X size={size} />;
  let style: any = check == 1 ? 'text-green-700' : 'text-red-700';

  return <span className={`${style} text-sm`}>{icon}</span>;
};

export default Checkmark;
