import React, { FC } from 'react';
import { Eye } from 'react-feather';
import { useDispatch } from 'react-redux';
import Table from '../../../components/tables/Table';
import TableItem from '../../../components/tables/TableItem';
import TableRow from '../../../components/tables/TableRow';
import Checkmark from '../../../components/tags/Checkmark';
import Tag from '../../../components/tags/Tag';
import Typography from '../../../components/typography/Typography';
import { setRootAside } from '../../../state/component/AsideSlice';
import { TruncateString } from '../../../utils/text/TruncateString';

type props = {
  assets: object[];
};

const UserHardware: FC<props> = ({ assets }) => {
  const dispatch = useDispatch();

  const viewDetail = (asset: string[]) => {
    dispatch(
      setRootAside({
        component: 'assetDetail',
        data: { asset: asset },
        title: 'Hardware',
      })
    );
  };

  const tableHeaders: object = [
    // "Factuurnummer",
    // "Factuurdatum",
    // "Gebruiker",
    'Product',
    'Serienummer',
    'Tags',
    'Remote managed',
    '',
  ];

  return (
    <div className="h-full">
      {assets?.length > 0 ? (
        <div className="border-b dark:border-gray-700">
          <Table header={tableHeaders}>
            {assets?.map((asset: any) => (
              <TableRow key={asset.ordSer_id} onClick={() => viewDetail(asset)}>
                {/* <TableItem type="stickyLeft">
                      {asset.order_product.ordPro_invoicenr ?? "/"}
                    </TableItem>
                    <TableItem>
                      <DateFormatted date={asset.created_at} />
                    </TableItem> */}
                <TableItem>
                  <TruncateString text={asset.ordPro_naam} limit={19} />
                </TableItem>
                <TableItem>{asset.ordSer_serial}</TableItem>
                <TableItem>
                  {asset.tags.length >= 0 && (
                    <div className="flex gap-1">
                      {asset?.tags
                        ?.slice(0, 1)
                        .map((tag, index) => (
                          <Tag key={index} style={'muted'} label={tag.tag} />
                        ))}
                    </div>
                  )}
                </TableItem>
                <TableItem>
                  <Checkmark check={asset.device_id ? 1 : 0} />
                </TableItem>
                <TableItem>
                  <button onClick={() => viewDetail(asset)}>
                    <Eye size={18} />
                  </button>
                </TableItem>
              </TableRow>
            ))}
          </Table>
        </div>
      ) : (
        <div className="h-full justify-center items-center">
          <Typography type={'default'} tag={'h1'}>
            Geen hardware
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserHardware;
