import React, { FC } from 'react';
import { Globe, MessageSquare, Phone } from 'react-feather';
import Typography from '../Typography';

interface ratePlan {
  ratePlan: {
    [key: string]: string;
  };
}

const RatePlanInfo: FC<ratePlan> = ({ ratePlan }) => {
  const listItem = (value: string, icon: any) => {
    return value ? (
      <li className="inline-flex items-center ml-3">
        {icon}
        <Typography tag="span" type="label">
          {value}
        </Typography>
      </li>
    ) : (
      ''
    );
  };

  return (
    <ul className="flex flex-col sm:flex-row">
      {listItem(ratePlan.trf_bellen, <Phone size={14} className="mr-1" />)}
      {listItem(ratePlan.trf_sms, <MessageSquare size={14} className="mr-1" />)}
      {listItem(ratePlan.trf_surfData, <Globe size={14} className="mr-1" />)}
    </ul>
  );
};

export default RatePlanInfo;
