import React, { FC } from 'react';
import { ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AsideInterface } from '../../state/component/AsideSlice';

type Props = {
  links: AsideInterface[];
  onclick: (title: string) => void;
};

const Crumbs: FC<Props> = ({ links, onclick }) => {
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(links).length > 1 && (
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            {links.map((link) => (
              <li key={link.title}>
                <div className="flex items-center">
                  {!!link.parent ? <ChevronRight size={14} /> : ''}
                  <button
                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                    onClick={() => onclick(link.title)}
                  >
                    {t(link.title)}
                  </button>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
};

export default Crumbs;
