import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleChange: Function;
  placeholder: string;
  search: string;
  filter?: HTMLElement;
};

const SearchTable: FC<Props> = ({ handleChange, placeholder, search }) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <input
        type="search"
        className="bg-transparent mb-2 border-b border-0 py-3 w-full rounded-none px-6 dark:border-gray-700"
        placeholder={t(placeholder)}
        onChange={(e) => handleChange(e)}
        value={search}
      />
      {/* <InputField
        type="search"
        style="transparant"
        placeholder={placeholder}
        onChange={handleChange}
      /> */}
    </div>
  );
};

export default SearchTable;
