import { InboxOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Upload, message } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../typography/Typography';

const { Dragger } = Upload;

type Props = {
  fileTypes?: string[];
  fileList: UploadFile[];
  onChange: (updatedFileList: UploadFile[]) => void;
};

const FileUpload: FC<Props> = ({ fileTypes, fileList, onChange }) => {
  const { t } = useTranslation();

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    fileList,
    onRemove: (file) => {
      const updatedFileList = fileList.filter((f) => f.uid !== file.uid);
      onChange(updatedFileList);
    },
    beforeUpload: (file) => {
      const isAllowedType = fileTypes ? fileTypes.includes(file.type) : true;
      if (!isAllowedType) {
        message.error(`Het bestandtype: ${file.type} wordt niet ondersteund.`);
        return Upload.LIST_IGNORE;
      }
      onChange([...fileList, file]);
      return false;
    },
  };

  return (
    <div>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {t('Klik hier of sleep een bestand naar dit gebied om te uploaden.')}
        </p>
        <p className="ant-upload-hint">
          {t(
            'Upload hier de ingevulde versies van de documenten die u gedownload heeft.'
          )}
        </p>
      </Dragger>
      <div className="mt-1">
        <Typography
          tag="p"
          type="label"
          color="noColor"
          addClass={'text-red-500'}
        >
          <strong>!</strong>{' '}
          {t(
            'Zorg ervoor dat u de nodige documenten heeft geupload voor u het formulier indient.'
          )}
        </Typography>
      </div>
    </div>
  );
};

export default FileUpload;
