import { createSlice } from '@reduxjs/toolkit';

interface SearchBarState {
  isVisible: boolean;
}

const initialState: SearchBarState = {
  isVisible: false,
};

const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState,
  reducers: {
    showSearchBar(state) {
      state.isVisible = true;
    },
    hideSearchBar(state) {
      state.isVisible = false;
    },
    toggleSearchBar(state) {
      state.isVisible = !state.isVisible;
    },
  },
});

export const { showSearchBar, hideSearchBar, toggleSearchBar } =
  searchBarSlice.actions;

export default searchBarSlice.reducer;
