import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import DateRange from '../../inputs/dates/DateRange';
import Typography from '../../typography/Typography';
import Form from '../Form';

import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimStatusTag } from '../../tags/StatusTag';

export interface Props {
  simData: SimcardInterface;
  setSim: any;
}

const TempDisable: FC<Props> = ({ simData, setSim }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([dayjs()]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const prop = {
      sim_isActive: SimStatusTag.TEMP_DISABLED,
    };

    setSim((state: SimcardInterface) => ({
      ...state,
      ...prop,
    }));
    dispatch(patchSimcard({ props: prop, simId: simData.sim_id }));

    setLoading(false);

    await ticketService.createTicketZendesk(companyId, {
      subject: `Tijdelijk buiten dienst zetten`,
      description: `Tijdelijk buiten dienst zetten: voor simkaart ${simData.sim_unique_nr}`,
      department: 'Telecom',
      linkedResource: {
        id: simData.sim_unique_nr,
        type: 'TELECOM',
      },
      // meta: sim,
    });

    dispatch(
      addToast({
        style: 'success',
        description: t(
          `Ticket aangemaakt om {{sim_nr}} tijdelijk buiten dienst te zetten.`,
          { sim_nr: simData.sim_unique_nr }
        ),
      })
    );
  };

  const handleDate = (date: Date) => {
    setDate(date);
  };

  return (
    <div>
      <Typography tag="span" type="label" addClass="mb-5 block">
        De simkaart wordt op inactief geplaatst
      </Typography>
      <Form
        buttonLabel="indienen"
        handleSubmit={handleSubmit}
        loading={loading}
        gridSize="grid-col-2"
      >
        <div className="block mt-2 mb-2">
          <Typography tag="label" type="label">
            Start en einddatum
          </Typography>
          <div className="block">
            <DateRange onChange={handleDate} value={date} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TempDisable;
