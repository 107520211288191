import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UsersService } from '../../services/users/Users';

const service = new UsersService();

interface UserSlice {
  user: object;
  users: object[];
  loading: boolean; // Add loading state
  error: string | undefined;
}

const initialState = {
  user: {},
  users: [],
  loading: true, // Initialize loading state
  error: '',
};

export const fetchUser = createAsyncThunk('auth/user', async () => {
  try {
    const response = await service.getAuthUser();
    return response;
  } catch (error) {
    throw error;
  }
});

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    updateUser: (state, action) => {
      const { id, updatedData } = action.payload;
      const existingUser = state.users.find((user) => user.geb_id === id);
      if (existingUser) {
        Object.assign(existingUser, updatedData);
      }
    },
    createUser: (state, action: PayloadAction<object>) => {
      state.users.push(action.payload);
    },
    deleteUser: (state, action: PayloadAction<number>) => {
      state.users = state.users.filter(
        (user) => user.geb_id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true; // Set loading to true when the fetchUser request is pending
    });
    builder.addCase(
      fetchUser.fulfilled,
      (state, action: PayloadAction<object>) => {
        state.user = action.payload;
        state.error = ''; // Clear error on successful fetch
        state.loading = false; // Set loading to false after fetch is complete
      }
    );
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false; // Set loading to false on fetch error
    });
  },
});

export const { setUser, setUsers, updateUser, createUser, deleteUser } =
  UserSlice.actions;

export default UserSlice.reducer;
