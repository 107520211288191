import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LicenseService } from '../../services/licenses/Licenses';

const service = new LicenseService();

interface License {
  [licenseId: string]: {
    quantity: number;
    name: string;
  };
}

export interface LicenseUser {
  id: number;
  id_user: number;
  geb_naam: string;
  geb_voornaam: string;
  license_amount: number;
}

interface CompanyLicense {
  id: number;
  id_bedrijf: number;
  id_licentie: number;
  aantal: number;
  lic_licentie: string;
  lic_productcode: string;
  lic_type: string;
  periode: string;
  prijs: number;
  users: LicenseUser[];
}

interface LicenseState {
  licenses: License[];
  companyLicenses: CompanyLicense[];
  loading: boolean;
}

const initialState: LicenseState = {
  licenses: [],
  companyLicenses: [],
  loading: false,
};

export const fetchCompanyLicenses = createAsyncThunk(
  'license/fetchCompanyLicenses',
  async (companyId: number) => await service.getLicenses(companyId)
);

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    addLicense: (state, action: PayloadAction<License>) => {
      const newLicense = action.payload;
      Object.keys(newLicense).forEach((id) => {
        const existingLicense = state.licenses[id];
        if (existingLicense) {
          existingLicense.quantity += newLicense[id].quantity;
        } else {
          state.licenses[id] = { ...newLicense[id] };
        }
      });
    },

    removeLicense: (state, action: PayloadAction<string>) => {
      state.licenses = state.licenses.filter(
        (license) => license.id !== action.payload
      );
    },
    updateLicenseQuantity: (
      state,
      action: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const { id, quantity } = action.payload;
      const license = state.licenses[id];
      if (license) {
        state.licenses[id] = {
          ...license,
          quantity: Math.max(license.quantity - 1, 0),
        };
      }
    },
    clearLicenses: (state) => {
      state.licenses = [];
    },
    setCompanyLicenses: (state, action: PayloadAction<CompanyLicense[]>) => {
      state.companyLicenses = action.payload;
    },
    addCompanyLicense: (state, action: PayloadAction<CompanyLicense>) => {
      state.companyLicenses = [...state.companyLicenses, action.payload];
    },
    updateCompanyLicense: (
      state,
      action: PayloadAction<{
        id: number;
        updatedLicense: Partial<CompanyLicense>;
      }>
    ) => {
      const { id, ...updatedLicense } = action.payload;
      const licenseIndex = state.companyLicenses.findIndex(
        (license) => license.id === id
      );

      if (licenseIndex !== -1) {
        state.companyLicenses[licenseIndex] = {
          ...state.companyLicenses[licenseIndex],
          ...updatedLicense,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyLicenses.fulfilled, (state, action) => {
      state.companyLicenses = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchCompanyLicenses.pending, (state) => {
      state.companyLicenses = [];
      state.loading = true;
    });
    builder.addCase(fetchCompanyLicenses.rejected, (state) => {
      state.companyLicenses = [];
      state.loading = false;
    });
  },
});

export const {
  addLicense,
  removeLicense,
  updateLicenseQuantity,
  clearLicenses,
  setCompanyLicenses,
  addCompanyLicense,
  updateCompanyLicense,
} = licenseSlice.actions;
export default licenseSlice.reducer;
