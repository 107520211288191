import apiClient from '../../../api-client';

export class AssetsService {
  public async getAssets(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserAssets(companyId: number, userId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/user/${userId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getAssetTickets(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets`,
        { type: 'HARDWARE', type_value: assetId }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async assetDetail(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async deleteAsset(companyId: number, assetId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting asset: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async rebootNinjaDevice(companyId: number, assetId: number) {
    // Still needs to be checked if it works
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/reboot`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error rebooting device: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async fetchNinjaServices(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/services`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device services: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async changeStateNinjaService(
    companyId: number,
    assetId: number,
    serviceName: string,
    state: string
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/services/${serviceName}/state`,
        {
          state: state,
        }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error changing device service state: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async fetchNinjaApps(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/apps`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device apps: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async fetchNinjaActivities(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/activities`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device activities: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async fetchNinjaCompanyDevices(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/ninja`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device devices: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async addHardware(companyId: number, asset: Object) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets`,
        {
          serialNumber: asset.serialNumber,
          name: asset.name,
          category: asset.category,
          deviceId: asset.deviceId ? String(asset.deviceId) : null,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating asset: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateAssetUser(
    companyId: number,
    assetId: number,
    newUserId: number,
    userName: string
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/adduser/${newUserId}`,
        { userName: userName }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error removing user from asset: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createTag(companyId: number, assetId: number, tag: string) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/tags`,
        {
          tag: tag,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating tag on: ${assetId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async deleteTag(companyId: number, assetId: number, tagId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/tags/${tagId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device activities: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async removeUser(companyId: number, assetId: number) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/removeuser`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error removing user from asset: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async updateAssetsUser(
    companyId: number,
    assetIds: string[],
    userId: number | null = null
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/adduser`,
        { assetIds, userId: userId }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding/removing user to/from assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }
}
