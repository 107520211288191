import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UsersService } from '../../services/users/Users';
import { setModules, setRoles } from '../../state/auth/AuthSlice';
import {
  setCompanies,
  setSelectedCompany,
} from '../../state/companies/CompaniesSlice';
import { setProfile } from '../../state/profile/ProfileSlice';
import { RootState } from '../../state/store';

const AuthCheckContainer: FC = () => {
  const dispatch = useDispatch();
  const usersService = new UsersService();

  const selectedCompany = useSelector(
    (state: RootState) => state.companies.selectedCompany
  );

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const authUser = await usersService.getAuthUser();

        dispatch(
          setRoles([
            {
              role: authUser.user.geb_role,
              companyId: authUser.user.geb_bed_id,
            },
          ])
        );

        dispatch(setModules(authUser.modules));

        dispatch(
          setCompanies(
            [...(authUser.companies || []), authUser.company].map(
              (company: any) => ({
                id: company.bed_id,
                companyName: company.bedNaam,
              })
            )
          )
        );

        if (!selectedCompany) {
          dispatch(
            setSelectedCompany({
              id: authUser.company.bed_id,
              companyName: authUser.company.bedNaam,
            })
          );
        }

        dispatch(
          setProfile({
            firstName: authUser.user.geb_voornaam,
            lastName: authUser.user.geb_naam,
            email: authUser.user.email,
            userId: authUser.user.geb_id,
          })
        );
      } catch (error) {
        console.log(error);
        window.location.href = `${process.env.GATSBY_SSO_REDIRECT_URI}`;
      }
    };

    fetchUserRoles();
  }, []);

  return <></>;
};

export default AuthCheckContainer;
