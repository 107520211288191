import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  style: string;
  label?: string | Object;
  icon?: ReactNode;
  lowercase?: boolean;
  extraClasses?: string;
};

const Tag: FC<Props> = ({ style, label, icon, lowercase, extraClasses }) => {
  const { t } = useTranslation();
  const styleModified: string = style?.toLocaleLowerCase();
  const styles = {
    danger: 'border border-red-700 bg-red-100 text-red-700 px-3 py-1',
    gesloten: 'bg-red-100 text-red-700 px-3 py-1',
    closed: 'bg-red-100 text-red-700 px-3 py-1',
    bussy: 'bg-orange-50 text-orange-400  px-3 py-1',
    lopende: 'bg-orange-50 text-orange-400 px-3 py-1',
    success: 'bg-green-100 text-green-700 px-3 py-1',
    open: 'bg-green-100 text-green-700 px-3 py-1',
    nieuw: 'bg-green-100 text-green-700 px-3 py-1',
    new: 'bg-green-100 text-green-700 px-3 py-1',
    muted: 'bg-slate-400 bg-opacity-10 px-3 py-1',
    hover:
      'bg-slate-400 bg-opacity-10 px-3 py-1 hover:bg-opacity-50 hover:text-slate-700',
    blue: 'border border-blue-700 text-blue-700 hover:text-white hover:bg-blue-700 dark:border-gray-700 dark:hover:border-blue-700 dark:text-white px-3 py-1',
    cyod: 'border border-blue-700 text-white bg-blue-700 dark:border-gray-700 px-3 py-1',
    neutral: 'normal-case font-regular',
  };

  return (
    <span
      className={`inline-flex items-center gap-1 flex-row rounded-full text-xs font-bold ${
        lowercase ? '' : 'uppercase'
      } ${styles[styleModified]} ${extraClasses}`}
    >
      {icon} {typeof label === 'string' ? t(label) : label}
    </span>
  );
};

export default Tag;
