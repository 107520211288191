import React, { FC } from 'react';
import BranchForm from '../../../components/forms/branches/BranchForm';
import TextHeader from '../../../components/headers/TextHeader';

export interface Props {
  data: {
    branch: any;
  };
}

const BranchDetailContainer: FC<Props> = ({ data: { branch } }) => {
  return (
    <div>
      <TextHeader title={branch.ves_name} subtitle={'Vestiging'} />
      <BranchForm branch={branch} />
    </div>
  );
};

export default BranchDetailContainer;
