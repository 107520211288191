import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LicenseRequest {
  id?: number;
  user_id: number;
  company_id: number;
  license_id: number;
  status: string;
  lic_licentie?: string;
  reason?: string;
  replied_by?: number;
  reply_reason?: string;
}

interface LicenseRequestState {
  licenseRequests: LicenseRequest[];
}

const initialState: LicenseRequestState = {
  licenseRequests: [],
};

const licenseRequestSlice = createSlice({
  name: 'licenseRequest',
  initialState,
  reducers: {
    setLicenseRequests: (state, action: PayloadAction<LicenseRequest[]>) => {
      state.licenseRequests = action.payload;
    },

    addLicenseRequest(state, action: PayloadAction<LicenseRequest>) {
      state.licenseRequests.push(action.payload);
    },

    removeLicenseRequest: (state, action: PayloadAction<string>) => {
      state.licenseRequests = state.licenseRequests.filter(
        (request) => request.id !== action.payload
      );
    },

    updateLicenseRequestStatus: (
      state,
      action: PayloadAction<{
        id: number;
        status: string;
        replyReason?: string;
      }>
    ) => {
      const { id, status, replyReason } = action.payload;
      const request = state.licenseRequests.find(
        (request) => request.id === id
      );
      if (request) {
        request.status = status;
        if (replyReason) {
          request.replyReason = replyReason;
        }
      }
    },

    clearLicenseRequests: (state) => {
      state = initialState;
    },
  },
});

export const {
  setLicenseRequests,
  addLicenseRequest,
  removeLicenseRequest,
  updateLicenseRequestStatus,
  clearLicenseRequests,
} = licenseRequestSlice.actions;

export default licenseRequestSlice.reducer;
