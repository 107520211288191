import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CartState {
  items: { [id: string]: CartItem };
  totalPrice: number;
  totalItems: number;
}

// Define the initial state
const initialState: CartState = {
  items: {},
  totalPrice: 0,
  totalItems: 0,
};

// Define the slice
const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    addItemToCart(state, action: PayloadAction<CartItem>) {
      const newItem: CartItem = action.payload;
      const id = Object.keys(newItem)[0];

      // Check if the item already exists in the cart
      if (state.items[id]) {
        state.items[id].quantity += 1;
      } else {
        // If the item doesn't exist, add it to the cart
        state.items[id] = {
          name: newItem[id].name,
          price: newItem[id].price / 1.21,
          quantity: 1,
          productInfo: newItem[id].productInfo,
        };
      }
      // Update total items and total price
      state.totalItems += 1;
      state.totalPrice += Number(newItem[id].price) / 1.21;
    },

    removeItemFromCart(state, action: PayloadAction<{ id: string }>) {
      const itemId = action.payload.id;
      if (state.items[itemId]) {
        const item = state.items[itemId];
        state.totalItems -= item.quantity;
        state.totalPrice -= item.price * item.quantity;
        delete state.items[itemId];
      }
    },

    increaseItemCount(state, action: PayloadAction<{ id: string }>) {
      const itemId = action.payload.id;
      if (state.items[itemId]) {
        state.items[itemId].quantity += 1;
        state.totalItems += 1;
        state.totalPrice += Number(state.items[itemId].price);
      }
    },

    decreaseItemCount(state, action: PayloadAction<{ id: string }>) {
      const itemId = action.payload.id;
      if (state.items[itemId]) {
        if (state.items[itemId].quantity === 1) {
          state.totalItems -= 1;
          state.totalPrice -= state.items[itemId].price;
          delete state.items[itemId];
        } else {
          state.items[itemId].quantity -= 1;
          state.totalItems -= 1;
          state.totalPrice -= Number(state.items[itemId].price);
        }
      }
    },

    clearCart(state) {
      state.items = {};
      state.totalItems = 0;
      state.totalPrice = 0;
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  increaseItemCount,
  decreaseItemCount,
  clearCart,
} = shopSlice.actions;

export default shopSlice.reducer;
