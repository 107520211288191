import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Toast {
  description: string | [string, {}];
  position?:
    | 'top'
    | 'topLeft'
    | 'topRight'
    | 'bottom'
    | 'bottomLeft'
    | 'bottomRight';
  style: string;
  duration?: number;
}

interface ToastState {
  toast: Toast | null;
}

const initialState: ToastState = {
  toast: null,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Toast>) => {
      state.toast = action.payload;
    },
    clearToast: (state) => {
      state.toast = null;
    },
  },
});

export const { addToast, clearToast } = toastSlice.actions;

export default toastSlice.reducer;
