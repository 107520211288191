import dayjs from 'dayjs';
import React, { FC, FormEvent, SetStateAction, useState } from 'react';
import DateInput from '../../inputs/dates/DateInput';
import Typography from '../../typography/Typography';
import Form from '../Form';

import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimStatusTag } from '../../tags/StatusTag';

type props = {
  simData: SimcardInterface;
  setSim: SetStateAction<SimcardInterface>;
};

const ConvertToPrepaid: FC<props> = ({ simData }, setSim) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const dispatch = useDispatch();

  const onChange = (date: Date) => {
    setDate(date);
  };

  const formatedDate = dayjs(date, 'DD/MM/YYYY');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const prop = {
      sim_status: 'Omschakeling Pay&Go',
    };

    setSim((state: SimcardInterface) => ({
      ...state,
      sim_isActive: SimStatusTag.SWAP_PAYNGO,
    }));

    dispatch(
      patchSimcard({
        props: { sim_isActive: SimStatusTag.SWAP_PAYNGO },
        simId: simData.sim_id,
      })
    );

    await simService.updateSimcard(
      simData.sim_id,
      {
        ...prop,
      },
      companyId
    );

    await ticketService.createTicketZendesk(companyId, {
      subject: `Omschakelen naar Pay&Go op ${formatedDate}`,
      description: `Ticket ${simData.sim_unique_nr} | omschakelen naar Pay&Go vanaf ${formatedDate} | GsmNr: ${simData.sim_gsm_nr}`,
      department: 'Telecom',
      linkedResource: {
        id: simData.sim_unique_nr,
        type: 'TELECOM',
      },
      // meta: sim,
    });

    dispatch(
      addToast({
        description: [
          'Omschakelen naar Pay&Go',
          {
            sim_unique_nr: simData.sim_unique_nr,
          },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );

    setLoading(false);
  };

  return (
    <>
      <Typography tag="span" type="label" addClass="mb-5 block">
        swap to prepaid
      </Typography>

      <Typography tag="span" type="label" addClass="block">
        Vanaf welke datum?
      </Typography>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <DateInput onChange={onChange} value={formatedDate} />
        <div className="mb-2"></div>
      </Form>
    </>
  );
};

export default ConvertToPrepaid;
