import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  searchTerm: string;
  selectedBrands: string[];
  priceRange: [number, number]; // Tuple representing min and max price
  cyod: boolean;
  category: string;
}

const initialState: FilterState = {
  searchTerm: '',
  selectedBrands: [],
  priceRange: [0, 10000], // Default price range
  cyod: false,
  category: '',
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<string>) {
      state.category = action.payload;
    },
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateSelectedBrands(state, action: PayloadAction<string[]>) {
      state.selectedBrands = action.payload;
    },
    updatePriceRange(state, action: PayloadAction<[number, number]>) {
      state.priceRange = action.payload;
    },
    updateCyodFilter(state, action: PayloadAction<boolean>) {
      state.cyod = action.payload;
    },
    resetFilters(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setCategory,
  updateSearchTerm,
  updateSelectedBrands,
  updatePriceRange,
  updateCyodFilter,
  resetFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
