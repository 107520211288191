import { Layout, Menu } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cmc from '../../images/cmc-group-f.png';
import { setSideBarOpen } from '../../state/notification/NotificationSlice';
import { RootState } from '../../state/store';

const { Sider } = Layout;

type MenuItem = {
  key: string;
  icon: React.ReactNode;
  label: React.ReactElement;
  children?: MenuItem[];
};

const DesktopNav = ({ middleMenuItems }: { middleMenuItems: MenuItem[] }) => {
  const sidebarOpen = useSelector(
    (state: RootState) => state.notification.sideBarOpen
  );

  const dispatch = useDispatch();

  const toggleSidebar = () => {
    const newSidebarState = !sidebarOpen;
    dispatch(setSideBarOpen(newSidebarState));
  };

  const siderStyle: React.CSSProperties = {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100vh',
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
    display: 'flex',
    backgroundColor: '#1d4ed8',
    position: 'relative',
  };

  return (
    <Sider
      collapsible
      collapsed={!sidebarOpen}
      onCollapse={() => toggleSidebar()}
      style={siderStyle}
      trigger={null}
      className="hidden md:flex"
    >
      <div
        className={`h-full flex flex-col justify-between items-center ${
          sidebarOpen ? 'w-[200px]' : 'w-[80px]'
        }`}
      >
        <div className="flex flex-col w-full">
          <button
            className={`py-4 font-bold ${sidebarOpen ? 'ml-1' : ''}`}
            onClick={() => toggleSidebar()}
          >
            <img className="px-2" src={Cmc} alt="CMC" />
          </button>
        </div>
        <Menu
          mode="inline"
          items={middleMenuItems}
          style={{ borderRight: '0px' }}
        />
        <div className="flex flex-col w-full invisible">
          <button
            className={`py-4 font-bold ${sidebarOpen ? 'ml-1' : ''}`}
            onClick={() => toggleSidebar()}
          >
            <img className="px-2" src={Cmc} alt="CMC" />
          </button>
        </div>
      </div>
    </Sider>
  );
};

export default DesktopNav;
