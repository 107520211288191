import { DatePicker, DatePickerProps, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsService } from '../../../services/project/Projects';
import { UsersService } from '../../../services/users/Users';
import { clearAsides } from '../../../state/component/AsideSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { updateUser } from '../../../state/user/UserSlice';
import FormButton from '../../buttons/FormButton';
import Typography from '../../typography/Typography';

interface Props {
  user: any;
}

const OffboardingForm: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [steps, setSteps] = useState<string[]>([]);
  const [dueDate, setDueDate] = useState<string>(
    dayjs(new Date()).add(1, 'week').format('YYYY-MM-DD')
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const userService = new UsersService();
  const projectService = new ProjectsService();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestedRelations = ['sim', 'hardware', 'licenties'];
        const userRelations = await userService.getUserRelations(
          companyId,
          user.geb_id,
          requestedRelations
        );

        const autoSteps = [];
        if (userRelations.simCount > 0) autoSteps.push('Telecom');
        if (userRelations.hardwareCount > 0) autoSteps.push('Hardware');
        if (userRelations.licentiesCount > 0) autoSteps.push('Licenties');

        setSteps(autoSteps);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [user]);

  const offboarding = async () => {
    setConfirmLoading(true);

    // Set user to inactive
    try {
      await userService.updateUser(companyId, user.geb_id, { geb_isActive: 0 });

      // Update redux store
      dispatch(
        updateUser({ id: user.geb_id, updatedData: { geb_isActive: 0 } })
      );
    } catch (error) {
      console.error('Error updating user status:', error);
      alert('Failed to update user status');
    }

    if (steps.length > 0) {
      const projectData = {
        proj_deadline: dueDate,
        proj_onderwerp: `Offboarding van ${user.geb_voornaam} ${user.geb_naam}`,
        proj_omschrijving: `Een project met de nodige stappen om ${user.geb_voornaam} ${user.geb_naam} te offboarden.`,
        proj_status: 'Nieuw',
      };

      interface Task {
        projFase_onderwerp: string;
        projFase_geschatte_tijd: number;
      }

      const tasks: Task[] = [];

      const stepDescriptions: any = {
        telecom: 'Simkaart(en) opzeggen of overdragen',
        hardware: 'Hardware ontkoppelen',
        licenties: 'Licenties Opzeggen of overdragen',
      };

      steps.forEach((step: string, index) => {
        const task = {
          projFase_onderwerp: step,
          projFase_geschatte_tijd: 7,
          assignments: [
            {
              proj_omschrijving: stepDescriptions[step.toLowerCase()],
              projFaseRegel_type: `${step}offboarding`,
            },
          ],
        };

        tasks.push(task);
      });

      const newProject = {
        data: projectData,
        tasks,
        metadata: {
          userId: user.geb_id,
        },
      };

      const projectId = await projectService.createProject(
        newProject,
        companyId
      );

      const formData = new FormData();
      formData.append('user', JSON.stringify(user));
      formData.append('deadline', dueDate);

      dispatch(
        addToast({
          description: 'Het offboarding project is gestart.',
          position: 'bottomRight',
          style: 'success',
        })
      );

      navigate(`/project/detail/overview/?projectId=${projectId.result}`);
    }

    setConfirmLoading(false);

    dispatch(clearAsides());

    dispatch(
      addToast({
        description: 'De gebruiker is inactief gezet.',
        position: 'bottomRight',
        style: 'success',
      })
    );
  };

  const onDate: DatePickerProps['onChange'] = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setDueDate(formattedDate);
  };

  return (
    <div>
      <div className="mb-3">
        <Typography tag={'p'} type={'default'}>
          Bij offboarding wordt er een project gestart met een overzicht van
          alles wat aan de gebruiker gelinkt is. Zo kan alles ontkoppeld worden
          om de gebruiker inactief te maken.
        </Typography>
        <Typography tag={'p'} type={'label'}>
          (Indien er niets meer aan de gebruiker gelinkt is wordt er geen
          project gemaakt.)
        </Typography>
        <div className="mt-5 mb-2 flex items-center gap-3">
          <p>Deadline:</p>
          <DatePicker
            defaultValue={dayjs(new Date()).add(1, 'week')}
            onChange={onDate}
            format={'DD-MM-YYYY'}
          />
        </div>
      </div>
      <Popconfirm
        title={t('Bent u zeker dat u de gebruiker wilt offboarden?')}
        description={t('Er zal een offboarding project gestart worden.')}
        onConfirm={offboarding}
        okText={t('Offboarden')}
        cancelText={t('Annuleer')}
        okButtonProps={{ loading: confirmLoading }}
        okType="danger"
        icon={null}
      >
        <FormButton text="Offboarden" />
      </Popconfirm>
    </div>
  );
};

export default OffboardingForm;
