import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '../../../components/boxes/Box';
import LoadingSpinner from '../../../components/buttons/LoadingSpinner';
import Table from '../../../components/tables/Table';
import TableItem from '../../../components/tables/TableItem';
import Typography from '../../../components/typography/Typography';
import { SimcardService } from '../../../services/simcards/Simcards';
import { RootState } from '../../../state/store';
import { SumObjects } from '../../../utils/numbers/SumObject';
import CurrencyFormatter from '../../../utils/text/CurrencyFormatter';

type SimUsage = {
  props: string[];
};

const SimUsagePerUser: FC<SimUsage> = ({ props }) => {
  const { t } = useTranslation();
  const service = new SimcardService();
  const [usage, setUsage] = useState([]);
  const [params, setParams] = useState(props);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const tableHeaders: object = ['Jaar', 'Maand', 'Prijs'];

  useEffect(() => {
    if (companyId) {
      try {
        service.getUsagePerUser(params, companyId).then((response) => {
          setUsage('results' in response ? response.results : response);
        });
      } catch (err) {
        console.error('Error fetching SIM data:', err);
      }
    }
  }, [props.phoneNumbers[0], companyId]);

  return (
    <>
      {usage.length > 0 ? (
        <Box gridSize="grid-1" type="noStyle">
          <div className="border overflow-y-hidden dark:border-gray-700 rounded-lg">
            <Table header={tableHeaders}>
              {usage.map((item, key) => (
                <tr key={key}>
                  <TableItem type="tiny">{item.year}</TableItem>
                  <TableItem type="tiny">{t(`m${item.month}`)}</TableItem>
                  <TableItem type="tiny">
                    <CurrencyFormatter price={item['sum(`value`)']} />
                  </TableItem>
                </tr>
              ))}
              <tr>
                <TableItem colspan={2}>
                  <Typography type="bold" tag="span">
                    Totaal
                  </Typography>
                </TableItem>
                <TableItem>
                  <Typography type="bold" tag="span">
                    <CurrencyFormatter
                      price={SumObjects(usage, 'sum(`value`)')}
                    />
                  </Typography>
                </TableItem>
              </tr>
            </Table>
          </div>
        </Box>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default SimUsagePerUser;
