import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { setDarkMode } from '../state/theme/themeSlice';

export default function useDarkSide() {
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const [colorTheme, setColorTheme] = useState(darkMode ? 'dark' : 'light');

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme === 'dark' ? 'light' : 'dark');
    root.classList.add(colorTheme);
    localStorage.setItem('theme', colorTheme);
    dispatch(setDarkMode(colorTheme === 'dark'));
  }, [colorTheme, dispatch]);

  return [colorTheme, setColorTheme];
}
