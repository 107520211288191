import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RatePlanInfo from '../../components/typography/sim/RatePlanInfo';
import { TarifService } from '../../services/simcards/Tarifs';
import { RootState } from '../../state/store';
import CurrencyFormatter from '../../utils/text/CurrencyFormatter';

const tarifService = new TarifService();

interface SelectTarif {
  onSelect: any;
  defaultValue: any;
  type: string;
}

const SelectTarif: FC<SelectTarif> = ({ onSelect, defaultValue, type }) => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [tarifs, setTarifs] = useState(['']);

  useEffect(() => {
    (async () => {
      tarifService.getCompanySpecif(companyId, type).then((res) => {
        setTarifs({ Voice: res });
      });
      tarifService
        .getTarrisFromCompanyContract(companyId, 'Data')
        .then((res) => {
          setTarifs((prevState) => ({
            ...prevState,
            Data: res.result,
          }));
        });
    })();
  }, []);

  if (!tarifs) {
    return <div>...loading</div>;
  }

  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      style={{ width: '100%' }}
      onChange={onSelect}
      optionFilterProp="label"
      optionLabelProp="searchLabel"
      id="5"
      options={Object.keys(tarifs).map((key: string) => ({
        label: (
          <span className="underline font-bold text-slate-950 dark:text-slate-200">
            {key}
          </span>
        ),
        title: key,
        options: Object.keys(tarifs[key]).map((trf) => ({
          label: (
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <p className="align-top ">{tarifs[key][trf].trf_naam}</p>
              </div>
              {tarifs[key][trf].price ? (
                <div className="flex flex-col justify-end text-right">
                  <p>
                    <CurrencyFormatter price={tarifs[key][trf].price} />
                  </p>
                  <RatePlanInfo ratePlan={tarifs[key][trf]} />
                </div>
              ) : (
                ''
              )}
            </div>
          ),
          searchLabel: tarifs[key][trf].trf_naam,
          value: tarifs[key][trf].trf_id,
        })),
      }))}
    />
  );
};

export default SelectTarif;
