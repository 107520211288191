import React from 'react';
import { Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Tag from '../../../components/tags/Tag';
import Typography from '../../../components/typography/Typography';
import { clearCyodSelection } from '../../../state/shop/cyodSlice';
import { clearCart } from '../../../state/shop/shopSlice';
import { RootState } from '../../../state/store';
import CalculateTotalPrice from '../../../utils/cart/CalculateTotalPrice';
import CurrencyFormatter from '../../../utils/text/CurrencyFormatter';

const PriceOverviewContainer = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const cyod = useSelector((state: RootState) => state.cyod);
  const branch = useSelector((state: RootState) => state.branch.selectedBranch);
  const dispatch = useDispatch();

  const handleClearCart = () => {
    dispatch(clearCart());
    dispatch(clearCyodSelection());
  };

  return (
    <>
      {cart.totalItems > 0 || Object.keys(cyod.selectedProducts).length > 0 ? (
        // Price overview
        <div className="p-5 relative flex items-center bg-slate-50 dark:bg-slate-800 rounded-t-lg md:rounded-s-lg 2xl:rounded-t-lg">
          <div className="w-full">
            <button
              className="absolute top-5 right-2"
              onClick={handleClearCart}
            >
              <Tag style={'danger'} label={<Trash2 size={18} />}></Tag>
            </button>
            <Typography tag={'h1'} type={'subtitle'}>
              Samenvatting
            </Typography>
            <div className="my-2">
              <div className="flex flex-row justify-between">
                <div>
                  <Typography tag={'span'} type={'default'}>
                    Prijs
                  </Typography>
                  &nbsp;
                  <Typography tag="span" type="smallest">
                    excl. BTW
                  </Typography>
                </div>
                <Typography tag={'p'} type={'default'}>
                  <CurrencyFormatter price={CalculateTotalPrice(cart, cyod)} />
                </Typography>
              </div>
              {cyod.usedCoupon > 0 ? (
                <div>
                  <div className="flex flex-row justify-between mb-2">
                    <div>
                      <Typography tag={'span'} type={'default'}>
                        Coupon
                      </Typography>
                      &nbsp;
                      <Typography tag="span" type="smallest">
                        excl. BTW
                      </Typography>
                    </div>
                    <Typography tag={'p'} type={'default'}>
                      -<CurrencyFormatter price={cyod.usedCoupon} />
                    </Typography>
                  </div>
                  <hr />
                  <div className="flex flex-row justify-between mt-2">
                    <div>
                      <Typography tag={'span'} type={'default'}>
                        Totaal
                      </Typography>
                      &nbsp;
                      <Typography tag="span" type="smallest">
                        excl. BTW
                      </Typography>
                    </div>
                    <Typography tag={'p'} type={'default'}>
                      <CurrencyFormatter
                        price={
                          CalculateTotalPrice(cart, cyod) - cyod.usedCoupon
                        }
                      />
                    </Typography>
                  </div>
                </div>
              ) : (
                ''
              )}
              {branch.ves_land && branch?.ves_land !== 'België' ? (
                <div className="flex flex-row justify-between">
                  <div>
                    <Typography tag={'span'} type={'default'}>
                      Leveringskosten
                    </Typography>
                    &nbsp;
                    <Typography tag="span" type="smallest">
                      excl. BTW
                    </Typography>
                  </div>
                  <Typography tag={'p'} type={'default'}>
                    <CurrencyFormatter price={15} />
                  </Typography>
                </div>
              ) : (
                ''
              )}
              <div className="flex flex-row justify-between">
                <Typography tag={'p'} type={'default'}>
                  BTW
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  <CurrencyFormatter
                    price={
                      branch.ves_land && branch?.ves_land !== 'België'
                        ? (CalculateTotalPrice(cart, cyod) -
                            cyod.usedCoupon +
                            15) *
                          0.21
                        : (CalculateTotalPrice(cart, cyod) - cyod.usedCoupon) *
                          0.21
                    }
                  />
                </Typography>
              </div>
            </div>
            <div className="pt-2 flex flex-row justify-between font-bold border-t dark:border-gray-700">
              <div>
                <Typography tag={'span'} type={'semibold'}>
                  Totaal
                </Typography>
                &nbsp;
                <Typography tag="span" type="smallest">
                  incl. BTW
                </Typography>
              </div>
              <Typography tag={'p'} type={'semibold'}>
                <CurrencyFormatter
                  price={
                    branch.ves_land && branch?.ves_land !== 'België'
                      ? (CalculateTotalPrice(cart, cyod) -
                          cyod.usedCoupon +
                          15) *
                        1.21
                      : (CalculateTotalPrice(cart, cyod) - cyod.usedCoupon) *
                        1.21
                  }
                />
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PriceOverviewContainer;
