import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import SelectSimcard from '../../../organisms/simcards/SelectSimcard';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import { SimStatusTag } from '../../tags/StatusTag';
import Typography from '../../typography/Typography';
import Form from '../Form';

type props = {
  currentValue: string;
  simData: SimcardInterface;
  setSim: any;
};

const SwapSimcard: FC<props> = ({ simData, setSim }) => {
  const [loading, setLoading] = useState(false);
  const [newSim, setNewSim] = useState('');
  const [error, setError] = useState('inactive');
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    setNewSim(event);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!newSim || (newSim !== 'eSIM' && newSim.length < 13)) {
      setError('error');
      setLoading(false);
      return false;
    }

    setSim((state: SimcardInterface) => ({
      ...state,
      sim_isActive: SimStatusTag.SWAP_SIM,
    }));

    dispatch(
      patchSimcard({
        props: { sim_isActive: SimStatusTag.SWAP_SIM },
        simId: simData.sim_id,
      })
    );

    await simService.updateSimcard(
      simData.sim_id,
      { sim_isActive: SimStatusTag.SWAP_SIM },
      companyId
    );

    const description = `Swap Sim van ${simData.sim_unique_nr} naar ${newSim}`;
    const subject = `Swap Sim ${simData.sim_unique_nr}`;

    await ticketService.createTicketZendesk(companyId, {
      subject: subject,
      description: description,
      department: 'Telecom',
      linkedResource: {
        id: simData.sim_unique_nr,
        type: 'TELECOM',
      },
    });

    dispatch(
      addToast({
        description: [
          'Swap sim',
          {
            sim_unique_nr: simData.sim_unique_nr,
          },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );
    setLoading(false);
    setError('success');
    setNewSim('');
  };

  return (
    <div>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <Typography tag="label" type="label">
          Selecteer nieuwe simkaart
        </Typography>
        <SelectSimcard
          onSelect={handleChange}
          defaultValue={newSim}
          error={error}
          status={'inactive'}
          includeESIM={true} // Pass prop to enable eSIM in SelectSimcard
        />
      </Form>
    </div>
  );
};

export default SwapSimcard;
