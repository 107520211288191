import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButton from '../../components/buttons/FormButton';
import DateInput from '../../components/inputs/dates/DateInput';
import FormField from '../../components/inputs/text/FormField';
import Typography from '../../components/typography/Typography';
import { TicketService } from '../../services/tickets/Tickets';
import { clearAsides } from '../../state/component/AsideSlice';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';

export interface Props {
  data: {
    license: any;
  };
}

const RequestLicenses: FC<Props> = ({ data: { license: licenseState } }) => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const dispatch = useDispatch();

  const onCreate = async (values: any) => {
    console.log(licenseState);
    try {
      const formattedDate = selectedDate.format('DD-MM-YYYY');

      const description = `Deze extra licenties moeten toegevoegd worden vanaf ${formattedDate}:\n${
        licenseState.lic_licentie
      }: ${values.amount} licentie${values.amount > 1 ? 's' : ''}`;

      await ticketService.createTicketZendesk(companyId, {
        subject: 'Meer licenties toevoegen',
        description: description,
      });

      dispatch(
        addToast({
          description: 'De licentie(s) zijn aangevraagd.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      closeAside();
    } catch (error) {
      console.error('Error requesting license:', error);
    }
  };

  const closeAside = () => {
    dispatch(clearAsides());
  };

  return (
    <div>
      <Typography tag={'p'} type={'default'}>
        Door extra licenties aan te vragen, stemt u in met de bijkomende kosten
        van deze licenties. De licenties worden geactiveerd vanaf de door u
        gekozen startdatum en zullen pas zichtbaar zijn na activatie. De
        volledige maand waarin de startdatum valt, zal in rekening worden
        gebracht.
      </Typography>
      <Form labelAlign="left" layout="vertical" form={form} onFinish={onCreate}>
        <div className="mt-5 grid grid-cols-2 gap-x-5">
          <div>
            <FormField
              type="number"
              label="Hoeveelheid"
              placeholder=""
              name="amount"
              form={form}
              isRequired={true}
            />
          </div>
          <div className="mb-3 col-span-2">
            <DateInput
              onChange={(date) => setSelectedDate(date)}
              value={selectedDate}
            />
          </div>
          <div className="col-span-2 my-2">
            <FormButton form={true} text={'Toevoegen'} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RequestLicenses;
