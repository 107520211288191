import { useLocation } from '@reach/router';
import { Dropdown, MenuProps } from 'antd';
import { navigate } from 'gatsby';
import i18n from 'i18next';
import React, { FC, ReactNode } from 'react';
import {
  Bell,
  Clipboard,
  Globe,
  LogOut,
  Search,
  ShoppingCart,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { UsersService } from '../../services/users/Users';
import { clearRoles } from '../../state/auth/AuthSlice';
import { setSelectedCompany } from '../../state/companies/CompaniesSlice';
import { setRootAside } from '../../state/component/AsideSlice';
import { toggleNotifications } from '../../state/notification/NotificationSlice';
import { clearProfile } from '../../state/profile/ProfileSlice';
import { toggleSearchBar } from '../../state/search/searchBarSlice';
import { RootState } from '../../state/store';
import { UserRoles, hasRole } from '../../utils/auth/AuthorizationUtility';
import useDarkSide from '../../utils/useDarkSlide';
import DropdownList from './DropdownList';

type Props = {
  children?: ReactNode;
};

const Navbar: FC<Props> = ({ children }) => {
  const profile = useSelector((state: RootState) => state.profile);
  const [colorTheme, setColorTheme] = useDarkSide();
  const service = new UsersService();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useLocation();
  const isShopPage = location.pathname.startsWith('/shop');

  const notificationCount = useSelector(
    (state: RootState) => state.notification.notificationCount
  );

  const companies = useSelector(
    (state: RootState) => state.companies.companies
  );

  const selectedCompany = useSelector(
    (state: RootState) => state.companies.selectedCompany
  );
  const shoppingCartData = useSelector((state: RootState) => state.cart);
  const cyod = useSelector((state: RootState) => state.cyod);
  const roles = useSelector((state: RootState) => state.auth.roles);

  const handleSearchClick = () => {
    dispatch(toggleSearchBar());
  };

  const calculateTotalItems = () => {
    let totalItems = shoppingCartData.totalItems;
    Object.values(cyod.selectedProducts).forEach((product) => {
      totalItems += 1;
      totalItems += product.accessories?.length;
    });
    return totalItems;
  };

  const openShoppingCartAside = () => {
    dispatch(
      setRootAside({
        component: 'shoppingCart',
        data: shoppingCartData,
        title: 'Winkelwagen',
      })
    );
  };

  const logOut = async () => {
    await service.logout();
    dispatch(clearRoles());
    dispatch(clearProfile());
    navigate(process.env.GATSBY_SSO_REDIRECT_URI);
  };

  const toggleDarkModeHandler = (checked) => {
    setColorTheme(checked ? 'dark' : 'light');
  };

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  type MenuItem = Required<MenuProps>['items'][number];

  const profileItems: MenuItem[] = [
    {
      key: '1',
      icon: (
        <DarkModeSwitch
          checked={colorTheme === 'dark'}
          onChange={toggleDarkModeHandler}
          size={18}
          sunColor="#F4BD29"
          moonColor="#ffffff"
        />
      ),
      label: t('Donkere modus'),
      onClick: () => toggleDarkModeHandler(colorTheme !== 'dark'),
    },
    {
      key: '2',
      icon: <Clipboard size={18} />,
      label: t('Mijn bestellingen'),
      onClick: () => navigate('/order/overview'),
    },
    {
      key: '3',
      danger: true,
      icon: <LogOut size={18} />,
      label: t('Afmelden'),
      onClick: () => logOut(),
    },
  ];

  const lowerMenuItems: MenuItem[] = [
    {
      key: '1',
      label: 'NL',
      onClick: () => onChangeLanguage('nl'),
    },
    {
      key: '2',
      label: 'FR',
      onClick: () => onChangeLanguage('fr'),
    },
    {
      key: '3',
      label: 'EN',
      onClick: () => onChangeLanguage('en'),
    },
  ];

  return (
    <div className="relative w-full">
      <div className="absolute top-0 right-0 p-2 md:p-0 md:top-5 md:right-5">
        <div className="flex flex-wrap md:flex-grow flex-row justify-center md:justify-end gap-1">
          <div className="flex">
            {hasRole(roles, UserRoles.ADMIN) && (
              <div
                className="p-2 text-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-gray-800 rounded hover:cursor-pointer"
                onClick={() => handleSearchClick()}
              >
                <div className="relative flex items-center">
                  <Search size={18} className="mt-0.5" />
                </div>
              </div>
            )}
            <div
              className={`${
                isShopPage ? 'block' : 'hidden'
              } p-2 text-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-gray-800 rounded hover:cursor-pointer`}
              onClick={() => openShoppingCartAside()}
            >
              <div className="relative flex items-center">
                <ShoppingCart size={18} className="mt-0.5" />
                {calculateTotalItems() > 0 && (
                  <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs text-white bg-red-500 border border-gray-200 rounded-full -top-1.5 -end-2">
                    {calculateTotalItems()}
                  </div>
                )}
              </div>
            </div>
            <div
              className="p-2 text-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-gray-800 rounded hover:cursor-pointer"
              onClick={() => dispatch(toggleNotifications())}
            >
              <div className="relative flex items-center">
                <Bell size={18} className="mt-0.5" />
                {notificationCount > 0 && (
                  <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs text-white bg-red-500 border border-gray-200 rounded-full -top-1.5 -end-2">
                    {notificationCount}
                  </div>
                )}
              </div>
            </div>
            <Dropdown menu={{ items: lowerMenuItems }} trigger={['click']}>
              <div className="flex items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-800 rounded hover:cursor-pointer">
                <Globe
                  size={18}
                  className="text-gray-800 dark:text-gray-200 mb-0.5"
                />
              </div>
            </Dropdown>
          </div>
          <div>
            <DropdownList
              button={selectedCompany?.companyName || 'Loading...'}
              items={companies.map((v) => ({
                label: v.companyName,
                onClick: () => dispatch(setSelectedCompany(v)),
              }))}
            />
          </div>
          <div className="pl-1">
            <Dropdown menu={{ items: profileItems }} trigger={['click']}>
              <button onClick={(e) => e.preventDefault()}>
                <div className="flex">
                  <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                    <span className="font-medium text-gray-600">
                      {profile.firstName.charAt(0)}
                      {profile.lastName.charAt(0)}
                    </span>
                  </div>
                  {/* {sidebarOpen && (
                    <div className="flex items-center justify-between">
                      <div className="pl-2 text-left">
                        <Typography
                          tag={"p"}
                          type={"label"}
                          color="white"
                          addClass="truncate max-w-28"
                        >
                          {profile.firstName} {profile.lastName}
                        </Typography>
                        <Typography
                          tag={"p"}
                          type={"label"}
                          color="noColor"
                          addClass="text-xs text-gray-200 truncate max-w-28"
                        >
                          {profile.email}
                        </Typography>
                      </div>
                      <MoreVertical color="white" className="pl-2" />
                    </div>
                  )} */}
                </div>
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
