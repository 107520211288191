import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import Cta from '../../buttons/Cta';
import { SimStatusTag } from '../../tags/StatusTag';

export interface Props {
  simData: SimcardInterface;
  setSim: any;
}

const ReActivate: FC<Props> = ({ simData, setSim }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const handleSubmit = async () => {
    setLoading(true);
    setSim((state: SimcardInterface) => ({
      ...state,
      sim_isActive: SimStatusTag.ACTIEF,
    }));
    dispatch(
      patchSimcard({
        props: { sim_isActive: SimStatusTag.ACTIEF },
        simId: simData.sim_id,
      })
    );

    await simService.updateSimcard(
      simData.sim_id,
      { sim_isActive: SimStatusTag.ACTIEF },
      companyId
    );

    const subject = `Sim ${simData.sim_unique_nr} activeren`;
    const description = `Opnieuw activeren van ${simData.sim_unique_nr}`;
    await ticketService.createTicketZendesk(companyId, {
      subject: subject,
      description: description,
      linkedResource: {
        id: simData.sim_unique_nr,
        type: 'TELECOM',
      },
    });
    setLoading(false);
  };

  return (
    <div className="flex justify-center items-center">
      <div className="text-center font-bold">
        <Cta
          text="Opnieuw activeren"
          style="danger"
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ReActivate;
