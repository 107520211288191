import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import LoadingSpinner from '../buttons/LoadingSpinner';
import Typography from '../typography/Typography';
import TooltipMetricGroups from './tooltip/TooltipMetricGroups';

const PieDiagram = (data: any) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);
  const [activePercentage, setActivePercentage] = useState(null);
  const colors = ['#583ef7', '#9cb9fc', '#7a7cfa', '#add8fe'];
  const graphValue = data.data.hasOwnProperty('results')
    ? data.data.results
    : data.data;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipMetricGroups
          price={payload[0].value}
          title={payload[0].payload.metric_group}
        />
      );
    }
  };

  const onMouseOver = useCallback((data, index) => {
    setActiveIndex(index);
    setActivePercentage(`${(data.percent * 100).toFixed(0)}%`);
  }, []);
  const onMouseLeave = useCallback((data, index) => {
    setActiveIndex(null);
    setActivePercentage(null);
  }, []);

  return (
    <div className="relative w-full h-full">
      {typeof graphValue[0] !== 'undefined' ? (
        <div className="absolute top-0 -left-2 flex flex-col z-10">
          <Typography tag="span" type="label">
            {`${graphValue[0].year}`}
          </Typography>
          <Typography tag="span" type="subtitle">
            {`m${graphValue[0].month}`}
          </Typography>
        </div>
      ) : (
        'Maand:'
      )}
      {data.loading ? (
        <LoadingSpinner />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Tooltip content={<CustomTooltip />} position={{ x: 200 }} />
            <Legend
              iconType="circle"
              layout="horizontal"
              align="center"
              verticalAlign="bottom"
              formatter={(value, entry, index) => (
                <Typography
                  tag="span"
                  addClass={`ml-2 ${activeIndex === index ? 'underline' : ''}`}
                  type="label"
                >
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </Typography>
              )}
              payload={graphValue.map((item: any, index: number) => ({
                id: item.metric_group,
                type: 'circle',
                value: `${t(item.metric_group)} ${new Intl.NumberFormat(
                  'nl-BE',
                  {
                    style: 'currency',
                    currency: 'EUR',
                  }
                ).format(item['sum(`value`)'])}`,
                color: colors[index % colors.length],
              }))}
            />
            <Pie
              data={graphValue}
              cx={'calc(100% - 100px)'}
              cy={'50%'}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              stroke="none"
              paddingAngle={3}
              activeIndex={activeIndex}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              dataKey="sum(`value`)"
            >
              <Label
                value={activePercentage ? activePercentage : ''}
                position="center"
                fontSize={24}
              />
              {graphValue.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default PieDiagram;
