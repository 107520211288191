import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import cyodReducer, { clearCyodSelection } from '../state/shop/cyodSlice';
import filterReducer, { resetFilters } from '../state/shop/filterSlice';
import cartReducer, { clearCart } from '../state/shop/shopSlice';
import simBrutoReducer from '../state/simdata/SimBrutoSlice';
import simcardReducer from '../state/simdata/SimcardsSlice';
import simDataReducer from '../state/simdata/SimDataSlice';
import themeReducer from '../state/theme/themeSlice';
import toastReducer from '../state/toast/ToastSlice';
import authReducer from './auth/AuthSlice';
import branchReducer from './branch/BranchSlice';
import companiesReducer from './companies/CompaniesSlice';
import AsideReducer from './component/AsideSlice';
import couponReducer from './coupon/CouponSlice';
import groupProductsReducer from './group/GroupProductsSlice';
import groupReducer from './group/GroupSlice';
import hardwareReducer from './hardware/HardwareSlice';
import licenseRequestReducer from './license/LicenseRequestSlice';
import licenseReducer from './license/LicenseSlice';
import notificationReducer from './notification/NotificationSlice';
import profileReducer from './profile/ProfileSlice';
import projectReducer from './project/ProjectSlice';
import searchReducer from './search/searchBarSlice';
import ticketsReducer from './tickets/ticketsSlice';
import UserReducer from './user/UserSlice';

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cyod', 'cart', 'filter', 'theme', 'companies'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    simData: simDataReducer,
    simBruto: simBrutoReducer,
    simcards: simcardReducer,
    cart: cartReducer,
    filter: filterReducer,
    user: UserReducer,
    group: groupReducer,
    branch: branchReducer,
    hardware: hardwareReducer,
    license: licenseReducer,
    licenseRequest: licenseRequestReducer,
    project: projectReducer,
    aside: AsideReducer,
    toast: toastReducer,
    cyod: cyodReducer,
    coupon: couponReducer,
    theme: themeReducer,
    groupProducts: groupProductsReducer,
    profile: profileReducer,
    auth: authReducer,
    searchBar: searchReducer,
    notification: notificationReducer,
    companies: companiesReducer,
    tickets: ticketsReducer,
  })
);

const clearLocalStorageMiddleware: Middleware =
  () => (next) => (action: any) => {
    if (action.type === 'CLEAR_LOCAL_STORAGE') {
      store.dispatch(clearCyodSelection());
      store.dispatch(clearCart());
      store.dispatch(resetFilters());
    }
    return next(action);
  };

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
        ignoredPaths: ['register', 'callback'],
        ignoredActionPaths: ['payload.callback'],
      },
    }).concat(clearLocalStorageMiddleware),
});

setInterval(
  () => {
    store.dispatch({ type: 'CLEAR_LOCAL_STORAGE' });
  },
  2 * 24 * 60 * 60 * 1000
); // 2 days in milliseconds

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
