import { Popconfirm } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/buttons/LoadingSpinner';
import Tabs from '../../components/tabs/Tabs';
import DateFormatted from '../../components/typography/Date';
import Time from '../../components/typography/Time';
import Typography from '../../components/typography/Typography';
import { AssetsService } from '../../services/assets/Assets';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';
import { Capitalize } from '../../utils/text/Capitalize';
import ProductStorage from './product/ProductStorage';
import WindowsActivities from './product/WindowsActivities';
import WindowsApps from './product/WindowsApps';
import WindowsServices from './product/WindowsServices';

const ProductInfo: FC = ({ asset, category }) => {
  const { t } = useTranslation();
  const [formLoading, setFormLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [assetData, setAssetData] = useState();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new AssetsService();
  const dispatch = useDispatch();

  useEffect(() => {
    setFormLoading(true);

    const fetchData = async () => {
      const assetData = await service.assetDetail(companyId, asset.ordSer_id);
      setAssetData(assetData);
      setFormLoading(false);
    };

    if (asset.device_id) {
      fetchData();
    } else {
      setFormLoading(false);
    }
  }, [asset]);

  const rebootDevice = async (device_id: number) => {
    setConfirmLoading(true);
    try {
      // await service.rebootNinjaDevice(device_id);
      dispatch(
        addToast({
          description: 'Het apparaat is verzocht te herstarten.',
          position: 'bottomRight',
          style: 'success',
        })
      );
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const tabs = [
    {
      nav: 'Opslag',
      component: [
        <ProductStorage volumes={assetData?.ninjaDeviceInfo?.volumes} />,
      ],
    },
  ];

  if (assetData?.ninjaDeviceInfo?.nodeClass === 'WINDOWS_WORKSTATION') {
    tabs.push(
      {
        nav: 'Windows services',
        component: [
          <WindowsServices
            deviceId={asset.device_id}
            assetId={asset.ordSer_id}
          />,
        ],
      },
      {
        nav: 'Windows apps',
        component: [<WindowsApps assetId={asset.ordSer_id} />],
      },
      {
        nav: 'Windows activiteiten',
        component: [<WindowsActivities assetId={asset.ordSer_id} />],
      }
    );
  }

  return (
    <div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {category === 'Smartphone' && assetData?.info !== null && (
            <div className="grid grid-cols-2 gap-6">
              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Provider
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {assetData?.info?.currentMncName ?? '/'}
                </Typography>
              </div>
              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Serienummer
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {assetData?.info?.serialNumber ?? '/'}
                </Typography>
              </div>

              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Batterij
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {Math.round(assetData?.info?.battery)}%
                </Typography>
              </div>
              <div>
                <Typography tag={'p'} type={'semibold'}>
                  CMC Service ID
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {assetData?.info?.userId ?? '/'}
                </Typography>
              </div>

              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Locatie
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {assetData?.location?.latitude
                    ? assetData?.location?.latitude +
                      ' ' +
                      assetData?.location?.longitude
                    : '/'}
                </Typography>
              </div>
              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Roaming
                </Typography>
                <div
                  className={`${
                    assetData?.info?.isRoaming === 'NotRoaming'
                      ? 'text-red-500'
                      : 'text-green-500'
                  }`}
                >
                  {assetData?.info?.isRoaming === 'NotRoaming'
                    ? 'Niet aan het roamen'
                    : 'Aan het roamen'}
                </div>
              </div>

              {assetData?.location?.latitude && (
                <div className="col-span-2">
                  <iframe
                    className="w-full h-80"
                    src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2497.876238896345!2d${assetData?.location?.longitude}015905342!3d${assetData?.location?.latitude}3805622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1snl!2sbe!4v1678715826781!5m2!1snl!2sbe`}
                  ></iframe>
                </div>
              )}
            </div>
          )}
          {(category === 'Desktop' ||
            category === 'Laptops' ||
            category === 'Werkstation') &&
            assetData?.ninjaDeviceInfo?.resultCode !== 'FAILURE' && (
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <Typography tag={'p'} type={'semibold'}>
                    Computernaam
                  </Typography>
                  <Typography tag={'p'} type={'default'}>
                    {assetData?.ninjaDeviceInfo?.systemName ?? '/'}
                  </Typography>
                </div>
                <div>
                  <Typography tag={'p'} type={'semibold'}>
                    Serienummer
                  </Typography>
                  <Typography tag={'p'} type={'default'}>
                    {assetData?.ninjaDeviceInfo?.system?.serialNumber ?? '/'}
                  </Typography>
                </div>

                <div>
                  <Typography tag={'p'} type={'semibold'}>
                    Laatste keer opgestart:
                  </Typography>
                  <Typography tag={'p'} type={'default'}>
                    <DateFormatted
                      date={assetData?.ninjaDeviceInfo?.lastContact * 1000}
                    />
                    &nbsp;
                    <Time
                      time={assetData?.ninjaDeviceInfo?.lastContact * 1000}
                    />
                  </Typography>
                  <Typography tag={'p'} type={'default'}>
                    {assetData?.ninjaDeviceInfo?.lastLoggedInUser ?? '/'}
                  </Typography>
                </div>
                <div>
                  <Typography tag={'p'} type={'semibold'}>
                    Huidige status
                  </Typography>
                  <div
                    className={`flex items-center gap-1 ${
                      assetData?.ninjaDeviceInfo?.offline
                        ? 'text-red-500'
                        : 'text-green-500'
                    }`}
                  >
                    {assetData?.ninjaDeviceInfo?.offline
                      ? 'Uitgeschakeld'
                      : 'Ingeschakeld'}
                  </div>
                </div>

                <div>
                  <Typography tag={'p'} type={'semibold'}>
                    Besturingssysteem
                  </Typography>
                  <Typography tag={'p'} type={'default'}>
                    {assetData?.ninjaDeviceInfo?.os?.name ?? '/'}
                  </Typography>
                </div>
                <div>
                  <Typography tag={'p'} type={'semibold'}>
                    Werkgeheugen
                  </Typography>
                  <Typography tag={'p'} type={'default'}>
                    {Math.floor(
                      assetData?.ninjaDeviceInfo?.memory?.capacity / 1024000000
                    ) ?? '/'}
                    GB
                  </Typography>
                </div>

                <div className="col-span-2">
                  <Typography tag={'p'} type={'semibold'}>
                    Processors
                  </Typography>
                  {assetData?.ninjaDeviceInfo?.processors?.map(
                    (processor: any) => (
                      <div>
                        <Typography tag={'p'} type={'default'}>
                          {processor?.name}
                        </Typography>
                        <Typography tag={'p'} type={'default'}>
                          Cores: {processor?.numCores}
                        </Typography>
                        <Typography tag={'p'} type={'default'}>
                          Threads: {processor?.numLogicalCores}
                        </Typography>
                        <Typography tag={'p'} type={'default'}>
                          Clockspeed:{' '}
                          {Math.round(
                            (processor?.clockSpeed / 1000000000) * 100
                          ) / 100}
                          GHz
                        </Typography>
                      </div>
                    )
                  )}
                </div>
                {assetData?.ninjaDeviceInfo?.offline ? (
                  ''
                ) : (
                  <div>
                    <Popconfirm
                      title={t('Herstart apparaat')}
                      description={t('Wilt u dit apparaat herstarten?')}
                      onConfirm={() => rebootDevice(asset.device_id)}
                      okText={t('Herstart')}
                      cancelText={t('Annuleer')}
                      okButtonProps={{ loading: confirmLoading }}
                      okType="danger"
                      icon={null}
                    >
                      <button className="px-3 py-1 rounded-lg border border-red-600 text-red-600 hover:text-red-800">
                        {t('Herstarten')}
                      </button>
                    </Popconfirm>
                  </div>
                )}
                <div className="col-span-2">
                  <Tabs tabs={tabs} />
                </div>
              </div>
            )}
          {(category !== 'Smartphone' &&
            category !== 'Desktop' &&
            category !== 'Laptops' &&
            category !== 'Werkstation') ||
          assetData?.errorMessage === 'Device not found' ||
          assetData?.info === null ? (
            <div className="grid grid-cols-2 gap-6">
              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Naam
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {asset?.ordPro_naam ?? '/'}
                </Typography>
              </div>
              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Serienummer
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {asset?.ordSer_serial ?? '/'}
                </Typography>
              </div>

              <div>
                <Typography tag={'p'} type={'semibold'}>
                  Gebruiker
                </Typography>
                <Typography tag={'p'} type={'default'}>
                  {Capitalize(asset.ordSer_username ?? '/')}
                </Typography>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ProductInfo;
