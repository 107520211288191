import { Slider } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFilters,
  updateCyodFilter,
  updatePriceRange,
  updateSearchTerm,
  updateSelectedBrands,
} from '../../state/shop/filterSlice';
import { RootState } from '../../state/store';
import CurrencyFormatter from '../../utils/text/CurrencyFormatter';
import InputField from '../inputs/text/InputField';
import ToggleSwitch from '../inputs/toggle/ToggleSwitch';
import Tag from '../tags/Tag';
import Typography from '../typography/Typography';

export interface FiltersSidebarProps {
  data: any;
}

const FiltersSidebar: FC<FiltersSidebarProps> = () => {
  const { t } = useTranslation();
  const filterState = useSelector((state: RootState) => state.aside.root);
  const selectedBrands = useSelector(
    (state: RootState) => state.filter.selectedBrands
  );
  const searchTerm = useSelector((state: RootState) => state.filter.searchTerm);
  const priceRange = useSelector((state: RootState) => state.filter.priceRange);
  const cyodFilter = useSelector((state: RootState) => state.filter.cyod);

  const [priceFilter, setPriceFilter] = useState<number[]>([
    Math.floor(priceRange[0] / 1.21),
    Math.ceil(priceRange[1] / 1.21),
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!filterState?.data) {
      return;
    }
    const categoryItems = filterState.data.categoryItems;

    const prices = Object.keys(categoryItems)
      .filter((key) => typeof categoryItems[key] !== 'string')
      .map((key) => {
        const item = categoryItems[key];
        return item.sale_price;
      });
    const lowestPrice = Math.min(...prices);
    const highestPrice = Math.max(...prices);
    if (priceFilter[0] !== lowestPrice && priceFilter[1] !== highestPrice) {
      setPriceFilter([
        Math.floor(lowestPrice / 1.21),
        Math.ceil(highestPrice / 1.21),
      ]);
      dispatch(
        updatePriceRange([
          Math.floor(lowestPrice / 1.21),
          Math.ceil(highestPrice / 1.21),
        ])
      );
    }
  }, [filterState?.data]);

  const toggleBrandSelection = (brandName: string) => {
    const updatedBrands = selectedBrands.includes(brandName)
      ? selectedBrands.filter((name) => name !== brandName)
      : [...selectedBrands, brandName];
    dispatch(updateSelectedBrands(updatedBrands));
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateSearchTerm(e.target.value));
  };

  const handlePriceRangeChange = (value: number[]) => {
    dispatch(updatePriceRange(value));
  };

  const toggleCyodFilter = () => {
    dispatch(updateCyodFilter(!cyodFilter));
  };

  const resetAllFilters = () => {
    dispatch(resetFilters());
    dispatch(updatePriceRange([priceFilter[0], priceFilter[1]]));
  };

  return (
    <div>
      <Typography tag={'h1'} type={'header'}>
        Filters
      </Typography>
      <button className="text-blue-700 my-1" onClick={resetAllFilters}>
        {t('Reset Filters')}
      </button>
      <div className="my-5 flex flex-col">
        <Typography tag="p" type="subtitle">
          Zoeken
        </Typography>
        <InputField
          type="text"
          placeholder="Zoeken"
          value={searchTerm}
          onChange={handleSearchTermChange}
          name="search"
        />
        <div className="mt-5">
          <Typography tag="p" type="subtitle">
            CYOD Filter
          </Typography>
          <div className="my-3">
            <ToggleSwitch
              label="CYOD"
              checked={cyodFilter}
              onChange={toggleCyodFilter}
              name="cyod"
            />
          </div>
        </div>
        <div className="my-3">
          <Typography tag="p" type="subtitle">
            Merken
          </Typography>
          {Object.values(filterState.data.brandList || {}).map(
            (brandName, index) => {
              if (
                typeof brandName === 'string' &&
                brandName !== 'filtersSidebar'
              ) {
                return (
                  <button
                    key={index}
                    onClick={() => toggleBrandSelection(brandName)}
                  >
                    <Tag
                      style={'blue'}
                      label={brandName}
                      extraClasses={`mr-2 my-2 ${
                        selectedBrands.includes(brandName)
                          ? 'bg-blue-700 text-white'
                          : ''
                      }`}
                    />
                  </button>
                );
              } else {
                return null;
              }
            }
          )}
        </div>
        <div className="flex flex-wrap gap-2 my-5">
          <div>
            <Typography tag="p" type="subtitle">
              Prijs:
            </Typography>
          </div>
          <div className="flex items-end gap-1 font-bold">
            <CurrencyFormatter
              tag="excl. BTW"
              price={priceRange[0]}
            ></CurrencyFormatter>
            -
            <CurrencyFormatter
              tag="excl. BTW"
              price={priceRange[1]}
            ></CurrencyFormatter>
          </div>
        </div>
        <Slider
          range
          min={priceFilter[0]}
          max={priceFilter[1]}
          defaultValue={priceRange}
          value={priceRange}
          onChange={handlePriceRangeChange}
          styles={{ track: { background: '#1d4ed8' } }}
        />
      </div>
    </div>
  );
};

export default FiltersSidebar;
