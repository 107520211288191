import React, { FC } from 'react';
import EditGroupForm from '../../../components/forms/groups/EditGroupForm';
import TextHeader from '../../../components/headers/TextHeader';

const GroupCreateContainer: FC = () => {
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Groep" />
      <EditGroupForm group={{}}></EditGroupForm>
    </div>
  );
};

export default GroupCreateContainer;
