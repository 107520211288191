import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { UserPlus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeThumb from '../../components/EmployeeThumb';
import { setRootAside } from '../../state/component/AsideSlice';
import { RootState } from '../../state/store';

type Props = {
  users: Object[];
};

const ProjectUsers: FC<Props> = ({ users }) => {
  const dispatch = useDispatch();
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );

  const maxDisplayedUsers = 3;
  const displayedUsers = users.slice(0, maxDisplayedUsers);
  const remainingUserCount = users.length - maxDisplayedUsers;

  const viewDetail = (users: []) => {
    dispatch(
      setRootAside({
        component: 'projectUsers',
        data: { users: users },
        title: 'Project gebruikers',
      })
    );
  };

  return (
    <div className="flex justify-end pr-2">
      <div className="flex -space-x-3 pr-3">
        {displayedUsers.map((user, index) => (
          <Tooltip title={user.name}>
            {user.photo ? (
              <EmployeeThumb
                image={user.photo}
                name={user.name}
                addClass="lg:w-10 lg:h-10 2xl:w-12 2xl:h-12"
              />
            ) : (
              <div
                key={index}
                className="relative inline-flex items-center justify-center w-8 h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 hover:scale-125 transition-all overflow-hidden bg-white dark:bg-slate-900 rounded-full ring-2 ring-blue-700"
              >
                <span className="font-medium text-gray-700 dark:text-gray-200 cursor-default">
                  {user.name.charAt(0)}
                </span>
              </div>
            )}
          </Tooltip>
        ))}
        {remainingUserCount > 0 && (
          <div className="relative inline-flex items-center justify-center w-8 h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 hover:scale-125 transition-all overflow-hidden bg-white dark:bg-slate-900 rounded-full ring-2 ring-blue-700">
            <span className="text-gray-700 dark:text-gray-200 cursor-default">
              +{remainingUserCount}
            </span>
          </div>
        )}
      </div>
      <div
        className="flex items-center cursor-pointer pl-3 border-l dark:border-gray-700"
        onClick={() => viewDetail(users)}
      >
        <Tooltip title={'Gebruikers toevoegen'}>
          <div className="relative inline-flex items-center justify-center w-8 h-8 lg:w-10 lg:h-10 2xl:w-12 2xl:h-12 hover:scale-125 transition-all overflow-hidden bg-white hover:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 rounded-full ring-2 ring-blue-700">
            <span className="text-gray-700 dark:text-gray-200">
              <UserPlus size={18} />
            </span>
          </div>
        </Tooltip>
        {/* {remainingUserCount > 0 && (
          <span className="ml-2">
            +{remainingUserCount} gebruiker{remainingUserCount > 1 && "s"}
          </span>
        )} */}
      </div>
    </div>
  );
};

export default ProjectUsers;
