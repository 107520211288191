import React, { FC } from 'react';
import Table from '../../../components/tables/Table';
import TableItem from '../../../components/tables/TableItem';
import TableRow from '../../../components/tables/TableRow';
import Typography from '../../../components/typography/Typography';

const UserLicense: FC = ({ licenses }) => {
  const tableHeaders: object = ['Licentie', 'Aantal'];

  return (
    <div>
      {licenses?.length > 0 ? (
        <div className="border-b dark:border-gray-700">
          <Table header={tableHeaders}>
            {licenses.map((lic) => (
              <TableRow key={lic.id}>
                <TableItem type="tiny">{lic.lic_licentie}</TableItem>
                <TableItem type="tiny">{lic.licentie_amount}</TableItem>
                {/* <TableItem type="tiny">
                  <CurrencyFormatter price={lic.licentie_aantal.prijs} />
                </TableItem> */}
                {/* <TableItem type="tiny">
                  <CurrencyFormatter
                    price={
                      lic.licentie_aantal.aantal * lic.licentie_aantal.prijs
                    }
                  />
                </TableItem> */}
              </TableRow>
            ))}
          </Table>
        </div>
      ) : (
        <div className="h-full justify-center items-center">
          <Typography type={'default'} tag={'h1'}>
            Geen licenties
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserLicense;
