import { Tabs as AntTabs, TabsProps } from 'antd';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  tabs: {
    nav: string;
    component: ReactNode;
  }[];
  operations?: ReactNode;
  activeKey?: string;
  onChange?: (key: string) => void;
};

const Tabs: FC<Props> = ({ tabs, operations, activeKey, onChange }) => {
  const transformTabsToItems = (tabs: Props['tabs']) => {
    const { t } = useTranslation();
    return tabs.map((tab, index) => ({
      key: String(index + 1),
      label: t(tab.nav),
      children: tab.component,
    }));
  };

  const items: TabsProps['items'] = transformTabsToItems(tabs);

  return (
    <AntTabs
      tabBarStyle={{ paddingLeft: '10px' }}
      tabBarExtraContent={operations}
      activeKey={activeKey}
      onChange={onChange}
      items={items}
    />
  );
};

export default Tabs;
