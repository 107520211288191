import { AutoComplete } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { SimcardInterface } from '../../interfaces/Simcard';
import { SimcardService } from '../../services/simcards/Simcards';
import { RootState } from '../../state/store';

const simcardService = new SimcardService();

type OptionType = { label: any; value: string };

type props = {
  onSelect: (value: string, option: OptionType | OptionType[]) => void;
  defaultValue?: string;
  status: string;
  error: '' | 'error' | 'warning' | undefined;
  disabled?: boolean;
  includeESIM?: boolean;
};

const SelectSimcard: FC<props> = ({
  onSelect,
  defaultValue,
  status,
  error,
  disabled,
  includeESIM = false,
}) => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [sims, setSims] = useState<SimcardInterface[]>([]);

  useEffect(() => {
    if (companyId) {
      simcardService.getSimcards(companyId, '', status).then((res) => {
        setSims(res as unknown as SimcardInterface[]);
      });
    }
  }, [companyId]);

  const options = [
    ...(sims.map((sim) => ({
      label: sim.sim_unique_nr,
      value: sim.sim_unique_nr,
    })) || []),
    ...(includeESIM ? [{ label: 'eSIM', value: 'eSIM' }] : []),
  ];

  return (
    <AutoComplete
      defaultValue={defaultValue}
      value={defaultValue}
      style={{ width: '100%' }}
      onChange={onSelect}
      onSelect={onSelect}
      optionFilterProp="label"
      status={error}
      allowClear={{ clearIcon: <X size={14} /> }}
      options={options}
      filterOption={(inputValue, option) => {
        console.log(option);
        return (
          option!.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}
      disabled={disabled}
    />
  );
};

export default SelectSimcard;
