import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UsersService } from '../../services/users/Users';
import { RootState } from '../../state/store';
import SelectBox from '../inputs/selectbox/SelectBox';

type Props = {
  handleSelect: (value: string, option: any) => void;
  defaultValue?: number;
};

const UserSelect: FC<Props> = ({ handleSelect, defaultValue = undefined }) => {
  const [users, setUsers] = useState<
    { label: string; value: string; searchLabel: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [defaultUser, setDefaultUser] = useState<number | undefined>(
    defaultValue
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new UsersService();

  useEffect(() => {
    setLoading(true);
    fetchUsers();
    setLoading(false);
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await service.getUserNames(companyId as number);
      const formattedUsers = data.map((user) => ({
        label: user.label,
        value: user.value as unknown as string,
        searchLabel: user.label,
      }));
      setUsers(formattedUsers);
      setDefaultUser(defaultValue);
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };

  return (
    <SelectBox
      onChange={handleSelect}
      defaultValue={defaultUser}
      options={users}
      loading={loading}
      search={true}
      notFoundText="No users found"
    />
  );
};

export default UserSelect;
