import { DatePicker } from 'antd';
import React from 'react';

import type { DatePickerProps, GetProps } from 'antd';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const { RangePicker } = DatePicker;

const DateRange = ({ onChange, value }) => {
  const onOk = (
    value: DatePickerProps['value'] | RangePickerProps['value']
  ) => {
    console.log('onOk: ', value);
  };

  const disableDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    return (
      current / 1000 < Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60 ||
      new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6
    );
  };

  return (
    <RangePicker
      format="DD-MM-YY"
      placeholder={['Startdatum', 'Einddatum']}
      value={value}
      defaultValue={value}
      locale={['BE']}
      disabledDate={disableDate}
      style={{ width: '100%' }}
      allowEmpty={true}
      onChange={onChange}
      onOk={onOk}
    />
  );
};

export default DateRange;
