import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssetsService } from '../../services/assets/Assets';

interface Tag {
  tag: string;
}

interface Hardware {
  ordSer_id: number;
  tags: Tag[];
  // other hardware properties
}

interface HardwareState {
  hardware: Hardware[];
  loading: boolean;
}

const initialState: HardwareState = {
  hardware: [],
  loading: false,
};

export const fetchHardware = createAsyncThunk(
  'hardware/fetchAssets',
  async (companyId: number) => {
    const service = new AssetsService();
    const data = await service.getAssets(companyId);
    return data;
  }
);

const hardwareSlice = createSlice({
  name: 'hardware',
  initialState,
  reducers: {
    updateHardware: (
      state,
      action: PayloadAction<{ id: number; updatedData: Partial<Hardware> }>
    ) => {
      const { id, updatedData } = action.payload;
      const existingHardware = state.hardware.findIndex(
        (hardware) => hardware.ordSer_id === id
      );

      if (existingHardware !== -1) {
        state.hardware[existingHardware] = {
          ...state.hardware[existingHardware],
          ...updatedData,
        };
      }
    },
    createHardware: (state, action: PayloadAction<Hardware>) => {
      state.hardware.push(action.payload);
    },
    deleteHardware: (state, action: PayloadAction<number>) => {
      state.hardware = state.hardware.filter(
        (hardware) => hardware.ordSer_id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHardware.fulfilled, (state, action) => {
      state.hardware = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchHardware.rejected, (state, action) => {
      state.hardware = [];
      state.loading = false;
    });
    builder.addCase(fetchHardware.pending, (state) => {
      state.hardware = [];
      state.loading = true;
    });
  },
});

export const { updateHardware, createHardware, deleteHardware } =
  hardwareSlice.actions;

export default hardwareSlice.reducer;
