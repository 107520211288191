import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextHeader from '../../components/headers/TextHeader';
import Tabs from '../../components/tabs/Tabs';
import Typography from '../../components/typography/Typography';
import CurrencyFormatter from '../../utils/text/CurrencyFormatter';
import CurrentLicenses from './CurrentLicenses';
import RequestLicenses from './RequestLicenses';

type License = {
  license: any;
  data: {
    license: any;
  };
};

const CompanyLicenseDetail: FC<License> = ({
  license,
  data: { license: licenseState },
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      nav: 'Huidige licenties',
      component: [
        <CurrentLicenses license={license} data={{ license: licenseState }} />,
      ],
    },
    {
      nav: 'Meer aanvragen',
      component: [<RequestLicenses data={{ license: licenseState }} />],
    },
  ];

  return (
    <div>
      <TextHeader title={licenseState.lic_licentie} />
      <Typography tag={'p'} type={'default'}>
        {licenseState.lic_informatie}
      </Typography>
      <div className="mt-1">
        <CurrencyFormatter price={licenseState.prijs} />
      </div>
      <div className="mt-1 flex justify-between">
        <Typography tag={'p'} type={'default'}>
          {t('Licenties')}: {licenseState.aantal}
        </Typography>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default CompanyLicenseDetail;
