import { notification } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { clearToast } from '../../state/toast/ToastSlice';

const ToastNotifications: React.FC = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const toast = useSelector((state: RootState) => state.toast.toast);
  const dispatch = useDispatch();

  useMemo(() => {
    if (toast) {
      const { description, style, position, duration = 3 } = toast;
      const antdStyle = style as 'success' | 'info' | 'warning' | 'error';

      const [key, variables] = description;

      api[antdStyle]({
        message:
          typeof description === 'string' ? t(description) : t(key, variables),
        duration: duration, // in seconds
        placement: position,
        onClose: () => {
          dispatch(clearToast());
        },
      });
    }
  }, [toast]);

  return <>{contextHolder}</>;
};

export default ToastNotifications;
