import apiClient from '../../../api-client';

interface Group {
  bedGr_naam: string;
  bedGr_bedrag: number;
  bedGr_periode: number;
  bedGr_ready: boolean;
  bedGr_deliveryChangeable: boolean;
  bedGr_requirements: string;
}

export class GroupsService {
  public async getGroups(companyId: number, search: string): Promise<Group[]> {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups${
          search ? `?search=${search}` : ''
        }`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching groups: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async getGroupNames(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/names`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching group names: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getGroupRequirements(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/requirements`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching group requirements: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getDeliveryChangeable(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/deliverychangeable`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching group delivery option: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUsersFromGroupById(companyId: number, groupId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/${groupId}/users`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching group users: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getCyodProducts(companyId: number, groupId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/${groupId}/products`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching group products: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async updateGroup(
    companyId: number,
    groupId: number,
    updatedData: Partial<Group>
  ) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/${groupId}`,
        updatedData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating group: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async createGroup(companyId: number, newGroupData: Partial<Group>) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups`,
        newGroupData
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating group: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async addGroupProducts(
    companyId: number,
    groupId: number,
    productIds: number[],
    category: string
  ) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/${groupId}/products`,
        { productIds: productIds, category: category }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating group products: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async removeGroupProducts(
    companyId: number,
    groupId: number,
    productIds: number[]
  ) {
    try {
      const response = await apiClient.delete(
        `${
          process.env.GATSBY_SSO_BACKEND_API_URL
        }/company/${companyId}/groups/${groupId}/products?productIds=${productIds.join(
          ','
        )}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting group products: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async deleteGroup(companyId: number, groupId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/groups/${groupId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting group: ${error.response?.statusText || error.message}`
      );
    }
  }
}
