import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/typography/Typography';

type CurrencyProps = {
  price: number;
  tag?: string;
};

const CurrencyFormatter: FC<CurrencyProps> = ({ price, tag }) => {
  const { t } = useTranslation();
  const formattedPrice = new Intl.NumberFormat('nl-BE', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);

  return (
    <span>
      {formattedPrice}
      {tag && (
        <Typography tag="span" type="smallest">
          &nbsp;{t(tag)}
        </Typography>
      )}
    </span>
  );
};

export default CurrencyFormatter;
