import { Button } from 'antd';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../components/typography/Typography';
import { LicenseService } from '../../services/licenses/Licenses';
import { TicketService } from '../../services/tickets/Tickets';
import { clearAsides } from '../../state/component/AsideSlice';
import { updateLicenseRequestStatus } from '../../state/license/LicenseRequestSlice';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';

type LicenseRequest = {
  licenserequest: any;
};

const LicenseRequestResponseForm: FC<LicenseRequest> = ({ licenserequest }) => {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new LicenseService();
  const ticketService = new TicketService();
  const dispatch = useDispatch();

  const company_id: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );

  const onApprove = async (requestId: number) => {
    const status = 'approved';
    try {
      setLoading(true);
      await service.updateUserRequestedLicense(requestId, company_id, {
        status: status,
        reply_reason: reason,
      });
      ticketService.createTicketZendesk(companyId, {
        subject: 'Licentie toevoegen',
        description: `De volgende licentie moet toegevoegd worden aan ${
          licenserequest.geb_voornaam + ' ' + licenserequest.geb_naam
        }:\n- ${licenserequest.lic_licentie}: 1 licentie`,
        department: 'IT',
        linkedResource: {
          id: requestId,
          type: 'LICENSEREQUEST',
        },
      });
      dispatch(
        addToast({
          description: 'Het verzoek is goedgekeurd.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      dispatch(
        updateLicenseRequestStatus({
          id: requestId,
          status: status,
          replyReason: reason,
        })
      );
      dispatch(clearAsides());
    } catch (error) {
      console.error('Error approving license request:', error);
    } finally {
      setLoading(false);
    }
  };

  const onDeny = async (requestId: number) => {
    const status = 'denied';
    try {
      setLoading(true);
      await service.updateUserRequestedLicense(requestId, company_id, {
        status: status,
        reply_reason: reason,
      });
      dispatch(
        addToast({
          description: 'Het verzoek is afgekeurd.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      dispatch(
        updateLicenseRequestStatus({
          id: requestId,
          status: status,
          replyReason: reason,
        })
      );
      dispatch(clearAsides());
    } catch (error) {
      console.error('Error denying license request:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography tag={'h2'} type={'subtitle'}>
        Verzoek behandelen
      </Typography>
      <div className="mt-2 mb-3">
        <Typography tag={'p'} type={'semibold'}>
          Reden
        </Typography>
        <textarea
          id="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows={4}
          className="border border-gray-300 dark:border-slate-600 dark:text-gray-300 text-sm rounded-lg block w-full px-2.5 py-1.5 mt-1 bg-transparent dark:bg-gray-900 focus:outline-blue-700"
        />
      </div>
      <div className="flex gap-1">
        <Button
          onClick={() => onApprove(licenserequest.id)}
          className="text-green-500 hover:text-green-700 mr-2"
          loading={loading}
        >
          Goedkeuren
        </Button>
        <Button
          onClick={() => onDeny(licenserequest.id)}
          className="text-red-500 hover:text-red-700"
          loading={loading}
        >
          Weigeren
        </Button>
      </div>
    </div>
  );
};

export default LicenseRequestResponseForm;
