import React, { FC, ReactNode } from 'react';
import SubmitButton from '../buttons/Submit/SubmitButton';

type Props = {
  children: ReactNode;
  handleSubmit?: any;
  buttonLabel: string;
  loading?: boolean;
  gridSize?: string;
  buttonStyle?: string;
};

const Form: FC<Props> = ({
  children,
  handleSubmit,
  buttonLabel,
  loading,
  gridSize,
  buttonStyle = 'default',
}) => {
  return (
    <form className="flex-initial" onSubmit={handleSubmit}>
      {loading}
      <div className="mb-3 ">
        {children}
        <SubmitButton
          text={buttonLabel}
          loading={loading}
          style={buttonStyle}
        />
      </div>
    </form>
  );
};

export default Form;
