import React, { ChangeEvent, FC, useState } from 'react';
import FloatingButton from '../../buttons/FloatingButton';
import Textarea from '../../inputs/text/Textarea';

interface Props {
  onSubmit: any;
}

const CommentForm: FC<Props> = ({ onSubmit }) => {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(0);
  const handleSubmit = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setLoading(1);
    setComment('');
    await onSubmit(comment);
    setLoading(0);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`${loading ? 'animate-pulse bg-gradient-to-r' : ''} relative`}
    >
      <Textarea
        style="buttonInline"
        placeholder={loading ? 'Verzenden...' : 'Comment versturen'}
        name="com_commentaar"
        onChange={handleChange}
        value={comment}
      />
      <FloatingButton icon="send" position="right" />
    </form>
  );
};

export default CommentForm;
