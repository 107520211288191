import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BranchesService } from '../../services/branches/Branches';
import { stackAside } from '../../state/component/AsideSlice';
import { RootState } from '../../state/store';
import FormButton from '../buttons/FormButton';
import LoadingSpinner from '../buttons/LoadingSpinner';
import SelectBox from '../inputs/selectbox/SelectBox';

type Props = {
  handleSelect: (value: string, option: Option) => void;
  defaultValue: string;
};

const BranchSelect: FC<Props> = ({ handleSelect, defaultValue }) => {
  const { t } = useTranslation();
  const [branchOptions, setBranchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const branchService = new BranchesService();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchBranchOptions()]);
  }, []);

  const fetchBranchOptions = async () => {
    setLoading(true);
    try {
      const data = await branchService.getBranchOptions(companyId);
      setBranchOptions(data);
      if (data.length > 0) {
        handleSelect(data[0].value, data[0]);
      }
    } finally {
      setLoading(false);
    }
  };

  const createBranch = async () => {
    dispatch(
      stackAside({
        component: 'branchCreate',
        title: 'Maak branch aan',
        data: { companyId },
        callback: () => {
          fetchBranchOptions();
        },
      })
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return !branchOptions || branchOptions.length === 0 ? (
    <FormButton onClick={createBranch} text={t('Maak vestiging aan')} />
  ) : (
    <SelectBox
      onChange={handleSelect}
      defaultValue={branchOptions[0]?.value}
      options={branchOptions}
      loading={loading}
      search={false}
      disabled={branchOptions.length === 1}
    />
  );
};

export default BranchSelect;
