import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../components/buttons/LoadingSpinner';
import TicketTable from '../../components/tickets/TicketTable';
import { TicketService } from '../../services/tickets/Tickets';
import { RootState } from '../../state/store';
import TicketForm from '../tickets/TicketForm';

const HardwareTickets = ({ assetId }) => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new TicketService();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      await fetchTickets();
      setLoading(false);
    };

    fetchData();
  }, [assetId]);

  const fetchTickets = async () => {
    // const tickets = await service.getAssetTickets(companyId, assetId.assetId);
    const tickets = await service.getTickets(companyId, 'HARDWARE', assetId);
    setTickets(tickets.zendesk);
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TicketTable tickets={tickets} />
        </>
      )}

      <div>
        <TicketForm type="HARDWARE" type_value={assetId} />
      </div>
    </div>
  );
};

export default HardwareTickets;
