import React, { FC } from 'react';
import { Check, FileText, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import TextHeader from '../../components/headers/TextHeader';
import Tag from '../../components/tags/Tag';
import Typography from '../../components/typography/Typography';
import { getTimeAgo } from '../../utils/dates/TimeAgoUtil';
import LicenseRequestResponseForm from './LicenseRequestResponseForm';

type LicenseRequest = {
  licenserequest: any;
  data: {
    licenseRequest: any;
  };
};

const LicenseRequestDetail: FC<LicenseRequest> = ({
  licenserequest,
  data: { licenseRequest: licenseRequestState },
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <TextHeader title={licenseRequestState.lic_licentie} />
      <Typography tag={'p'} type={'default'}>
        {licenseRequestState.lic_informatie}
      </Typography>
      <div className="mt-1 mb-3 space-y-2">
        {/* <Tag
          style={
            licenseRequestState.status === "pending"
              ? "lopende"
              : licenseRequestState.status === "approved"
              ? "success"
              : "gesloten"
          }
          label={licenseRequestState.status}
        /> */}
        <Typography tag={'p'} type={'semibold'}>
          {t('Aanvrager')}:{' '}
          {licenseRequestState.geb_voornaam +
            ' ' +
            licenseRequestState.geb_naam}
        </Typography>
        <Typography tag={'p'} type={'default'}>
          {t('Reden')}:{' '}
          {licenseRequestState.reason !== '' ? licenseRequestState.reason : '/'}
        </Typography>
      </div>
      <div className="pt-3 border-t dark:border-gray-700">
        {licenseRequestState.status === 'pending' ? (
          <LicenseRequestResponseForm licenserequest={licenseRequestState} />
        ) : (
          <ol className="relative border-s border-gray-200 dark:border-gray-700">
            <li className="mb-10 ms-6">
              <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 text-blue-700 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900">
                <FileText size={16} />
              </span>
              <Typography tag={'p'} type={'default'}>
                Status voor {licenseRequestState.lic_licentie} is&nbsp;
                <Tag style={'lopende'} label={'pending'} />
              </Typography>
              <Typography tag={'p'} type={'label'} addClass={'text-gray-500'}>
                {getTimeAgo(licenseRequestState.created_at, t)}
              </Typography>
            </li>
            <li className="mb-10 ms-6">
              <span
                className={`absolute flex items-center justify-center w-6 h-6 ${
                  licenseRequestState.status === 'approved'
                    ? 'bg-green-100 text-green-700'
                    : 'bg-red-100 text-red-700'
                } rounded-full -start-3 ring-8 ring-white dark:ring-gray-900`}
              >
                {licenseRequestState.status === 'approved' ? (
                  <Check size={16} />
                ) : (
                  <X size={16} />
                )}
              </span>
              <Typography tag={'p'} type={'default'}>
                Het verzoek is&nbsp;
                <Tag
                  style={
                    licenseRequestState.status === 'approved'
                      ? 'success'
                      : 'gesloten'
                  }
                  label={licenseRequestState.status}
                />
              </Typography>
              <Typography tag={'p'} type={'default'}>
                {licenseRequestState.reply_reason}
              </Typography>
              <Typography tag={'p'} type={'label'} addClass={'text-gray-500'}>
                {getTimeAgo(licenseRequestState.updated_at, t)}
              </Typography>
            </li>
          </ol>
        )}
      </div>
    </div>
  );
};

export default LicenseRequestDetail;
