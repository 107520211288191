import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalProducts: 0,
  products: [],
  allProductsCyod: false,
};

const groupProductsSlice = createSlice({
  name: 'groupProducts',
  initialState,
  reducers: {
    setTotalProducts: (state, action) => {
      state.totalProducts = action.payload.length;
    },
    setGroupProducts: (state, action) => {
      state.products = action.payload;
      state.allProductsCyod = action.payload.length === state.totalProducts;
    },
    addProductsToGroup: (state, action) => {
      state.products = [...state.products, ...action.payload];
      state.allProductsCyod = state.products.length === state.totalProducts;
    },
    removeProductsFromGroup: (state, action) => {
      state.products = state.products.filter(
        (product) => !action.payload.includes(product.id)
      );
      state.allProductsCyod = state.products.length === state.totalProducts;
    },
    setAllProductsCyod: (state, action) => {
      state.allProductsCyod = action.payload;
    },
  },
});

export const {
  setGroupProducts,
  addProductsToGroup,
  removeProductsFromGroup,
  setAllProductsCyod,
  setTotalProducts,
} = groupProductsSlice.actions;

export default groupProductsSlice.reducer;
