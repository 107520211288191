import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/buttons/LoadingSpinner';
import SearchTable from '../../../components/inputs/search/SearchTable';
import Table from '../../../components/tables/Table';
import TableItem from '../../../components/tables/TableItem';
import { AssetsService } from '../../../services/assets/Assets';
import { RootState } from '../../../state/store';
import { TruncateString } from '../../../utils/text/TruncateString';

const WindowsApps = ({ assetId }) => {
  const [search, setSearch] = useState('');
  const [formLoading, setFormLoading] = useState(true);
  const [appsData, setAppsData] = useState([]);
  const [filteredAppsData, setFilteredAppsData] = useState([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new AssetsService();

  useEffect(() => {
    setFormLoading(true);

    const fetchData = async () => {
      const appsData = await service.fetchNinjaApps(companyId, assetId);
      setAppsData(appsData);
      setFilteredAppsData(appsData);
      setFormLoading(false);
    };

    fetchData();
  }, [assetId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    filterApps(value);
  };

  const filterApps = (value: string) => {
    const filteredApps = appsData?.filter((app) =>
      app.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAppsData(filteredApps);
  };

  const tableHeaders: object = ['Installatiedatum', 'Naam', 'Versie'];

  return (
    <div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchTable
            handleChange={handleChange}
            placeholder="App zoeken..."
            value={search}
          />
          <Table header={tableHeaders}>
            {filteredAppsData?.map((app: any, index) => (
              <tr key={index}>
                <TableItem>{app.installDate}</TableItem>
                <TableItem>
                  {<TruncateString text={app.name} limit={30} />}
                </TableItem>
                <TableItem>{app.version}</TableItem>
              </tr>
            ))}
          </Table>
        </>
      )}
    </div>
  );
};

export default WindowsApps;
