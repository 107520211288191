import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimOnboardingForm from '../../components/forms/users/SimOnboardingForm';
import { ProjectsService } from '../../services/project/Projects';
import { SimcardService } from '../../services/simcards/Simcards';
import { TicketService } from '../../services/tickets/Tickets';
import { clearAsides } from '../../state/component/AsideSlice';
import { setCurrentProject } from '../../state/project/ProjectSlice';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';

export interface Props {
  data: {
    task: any;
  };
}

const SimOnboarding: FC<Props> = ({ data: { task } }) => {
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const projectService = new ProjectsService();
  const simcardService = new SimcardService();
  const ticketService = new TicketService();

  const dispatch = useDispatch();

  const handleSubmit = async (formData) => {
    const today = new Date();
    try {
      await simcardService.updateOrCreateProximusSim(
        {
          sim_unique_nr:
            formData.get('isESim') === 'true'
              ? null
              : formData.get('simcards[sim]'),
          sim_user_id: Number(formData.get('user_id')),
          sim_trf_id: Number(formData.get('simcards[tarifId]')),
          sim_status: 'Aanvraag activatie',
          sim_type: formData.get('isESim') === 'true' ? 'eSIM' : 'regular',
        },
        companyId
      );

      const description = `
      <div>
        <h3>Aanvraag activatie SIM-kaart</h3>
        <p><strong>SIM-nummer:</strong> ${
          formData.get('isESim') === true
            ? 'eSIM'
            : formData.get('simcards[sim]')
        }</p>
        <p><strong>Tariefplan:</strong> ${formData.get('simcards[tarif]')}</p>
        <p><strong>Status:</strong> Aanvraag activatie</p>
        <p><strong>Begindatum:</strong> ${formData.get('date')}</p>
      </div>
    `;

      await ticketService.createTicketZendesk(companyId, {
        subject: `Activatie-aanvraag SIM-kaart`,
        description,
        department: 'Telecom',
        linkedResource: {
          id: formData.get('simcards[sim]'),
          type: 'TELECOM',
        },
      });

      const assignments = [
        {
          proj_omschrijving: `Simkaart: ${formData.get(
            'simcards[sim]'
          )} met tariefplan: ${formData.get(
            'simcards[tarif]'
          )} aan de gebruiker toewijzen, vanaf${
            formData.get('date')
              ? ` ${formData.get('date')}.`
              : ` ${today.toLocaleDateString()}.`
          }`,
          projFaseRegel_type: 'cmctaak',
          projFaseRegel_extra: 'CMC',
        },
      ];

      dispatch(clearAsides());

      const project = await projectService.addAssignments(
        assignments,
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(
        addToast({
          description: 'De simkaart is aan de gebruiker toegevoegd.',
          position: 'bottomRight',
          style: 'success',
          duration: 0,
        })
      );

      dispatch(setCurrentProject(project.result));
    } catch (error) {
      console.error('Error adding assignments:', error);
    }
  };

  return (
    <div>
      <SimOnboardingForm onSubmit={handleSubmit} />
    </div>
  );
};

export default SimOnboarding;
