import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '../../../components/boxes/Box';
import LoadingSpinner from '../../../components/buttons/LoadingSpinner';
import PieDiagram from '../../../components/charts/Pie';
import { SimcardService } from '../../../services/simcards/Simcards';
import { RootState } from '../../../state/store';
import { GetDateMonthBack } from '../../../utils/dates/GetDateMonthBack';

const SimUserUsage = (phoneNumbers: object) => {
  const service = new SimcardService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [pieData, setPieData] = useState([]);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setPieData([]);
    if (companyId) {
      const fetchData = async () => {
        try {
          const lastUsageDate = await GetDateMonthBack(companyId);
          const res = await service.getBruto(
            {
              years: [lastUsageDate.year],
              months: [lastUsageDate.month],
              metricGroups: [
                'calls',
                'data',
                'mpay',
                'others',
                'sms',
                'subscriptions',
              ],
              ...phoneNumbers,
            },
            companyId
          );
          setPieData(res.results);
        } catch (err) {
          setNotFound(true);
          console.error('Error fetching SIM data:', err);
        }
      };
      fetchData();
    }
  }, [phoneNumbers, companyId]);

  return (
    <>
      {pieData.length > 0 ? (
        <Box type="bordered" size="medium" gridSize="grid-1">
          <PieDiagram data={pieData} />
        </Box>
      ) : notFound ? (
        'Geen data gevonden'
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default SimUserUsage;
