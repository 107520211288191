import { Select } from 'antd';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

interface Option {
  label: React.ReactNode;
  value: string;
  searchLabel?: string;
}

type Props = {
  onChange: (value: string, option: Option) => void;
  onSearch?: (searchTerm: string) => void;
  defaultValue?: string | number;
  options: Option[];
  loading?: boolean;
  props?: {};
  search: boolean;
  notFoundText?: string;
  suffixIcon?: ReactNode;
  disabled?: boolean;
};

const SelectBox: FC<Props> = ({
  onChange,
  onSearch,
  defaultValue,
  options,
  loading,
  props,
  search = false,
  disabled = false,
  notFoundText,
  suffixIcon,
}) => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const handleSearch = (value: string) => {
    onSearch && onSearch(value);
  };

  const filterOption = (input: string, option: any) => {
    const normalizedInput = input.trim().toLowerCase().replace(/\s+/g, '');
    const normalizedSearchLabel = option?.searchLabel
      ?.trim()
      .toLowerCase()
      .replace(/\s+/g, '');

    return normalizedSearchLabel.includes(normalizedInput);
  };

  const filterSort = (optionA: any, optionB: any) => {
    const searchLabelA = optionA?.searchLabel || '';
    const searchLabelB = optionB?.searchLabel || '';
    return searchLabelA.toLowerCase().localeCompare(searchLabelB.toLowerCase());
  };

  return (
    <Select
      showSearch={search}
      options={options}
      defaultValue={defaultValue}
      onSelect={onChange}
      onSearch={handleSearch}
      className="w-full"
      loading={loading}
      filterOption={filterOption}
      filterSort={filterSort}
      notFoundContent={notFoundText}
      suffixIcon={suffixIcon}
      disabled={disabled}
      {...props}
    />
  );
};

export default SelectBox;
