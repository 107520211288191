import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Accordeon from '../../../components/accordeon/Accordeon';
import LoadingSpinner from '../../../components/buttons/LoadingSpinner';
import OffboardingForm from '../../../components/forms/offboarding/OffboardingForm';
import EditUserForm from '../../../components/forms/users/EditUserForm';
import Tabs from '../../../components/tabs/Tabs';
import { AssetsService } from '../../../services/assets/Assets';
import { LicenseService } from '../../../services/licenses/Licenses';
import { UsersService } from '../../../services/users/Users';
import { RootState } from '../../../state/store';
import {
  CompanyModules,
  hasModule,
} from '../../../utils/auth/AuthorizationUtility';
import UserHardware from '../../dashboard/user/UserHardware';
import UserSims from '../../dashboard/user/UserSims';
import UserLicense from '../../users/licences/UserLicenses';

type User = {
  user: any;
  data: {
    user: any;
  };
};

const UserDetailContainer: FC<User> = ({ user, data }) => {
  const { t } = useTranslation();
  const [variousData, setVariousData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const modules = useSelector((state: RootState) => state.auth.modules);
  const assetService = new AssetsService();
  const licenseService = new LicenseService();
  const userService = new UsersService();

  useEffect(() => {
    if (data?.user?.geb_id && data.user.geb_id !== currentUserId) {
      // Fetch data if the current user ID has changed
      setCurrentUserId(data.user.geb_id);
      fetchData();
    }
  }, [data, currentUserId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchall();
      setVariousData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchall = async () => {
    const [userAssets, userLicenses, userSims] = await Promise.all([
      assetService.getUserAssets(companyId, data.user.geb_id),
      licenseService.getUserLicenses(companyId, data.user.geb_id),
      userService.getUserSims(companyId, data.user.geb_id),
    ]);

    return {
      userAssets,
      userLicenses,
      userSims,
    };
  };

  const panels = [
    ...(hasModule(modules, CompanyModules.TELECOM)
      ? [
          {
            key: '1',
            label: t('Simkaarten'),
            children: <UserSims sims={variousData?.userSims} />,
          },
        ]
      : []),
    ...(hasModule(modules, CompanyModules.HARDWARE)
      ? [
          {
            key: '2',
            label: t('Hardware'),
            children: <UserHardware assets={variousData?.userAssets} />,
          },
        ]
      : []),
    {
      key: '3',
      label: t('Licenties'),
      children: <UserLicense licenses={variousData?.userLicenses} />,
    },
    {
      key: '4',
      label: <span className=" text-red-700">{t('Offboarden')}</span>,
      children: <OffboardingForm user={data.user} />,
    },
  ];

  const tabs = [
    {
      nav: 'Overzicht',
      component: (
        <>
          <Accordeon panels={panels} activeKey={1} />
        </>
      ),
    },
    {
      nav: 'Bewerken',
      component: [<EditUserForm user={data.user} />],
    },
  ];

  return (
    <div>
      <h2 className=" text-2xl">
        <span className="block text-sm">
          {data.user.geb_isActive === 1
            ? 'Gebruiker'
            : data.user.geb_isActive === 2
              ? 'Medewerker'
              : 'Inactief'}
        </span>
        {data.user.geb_voornaam} {data.user.geb_naam}
      </h2>
      {loading ? <LoadingSpinner /> : <Tabs tabs={tabs} />}
    </div>
  );
};

export default UserDetailContainer;
