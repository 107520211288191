import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormButton from '../../../components/buttons/FormButton';
import TextHeader from '../../../components/headers/TextHeader';
import Typography from '../../../components/typography/Typography';
import { clearAsides } from '../../../state/component/AsideSlice';
import { RootState } from '../../../state/store';
import PriceOverviewContainer from './PriceOverviewContainer';
import ShoppingCartPage from './ShoppingCartOverviewContainer';

const ShoppingCartAside = () => {
  const { t } = useTranslation();
  const cart = useSelector((state: RootState) => state.cart);
  const cyod = useSelector((state: RootState) => state.cyod);
  const dispatch = useDispatch();

  const handleLinkClick = () => {
    dispatch(clearAsides());
  };

  return (
    <>
      {cart.totalItems > 0 || Object.keys(cyod.selectedProducts).length > 0 ? (
        <div>
          <TextHeader title="Winkelwagen" />
          <ShoppingCartPage />
          {/* Price overview */}
          <div className="mt-5">
            <PriceOverviewContainer />
          </div>
          <div className="mt-5">
            <Link to="/shop/cart/overview">
              <FormButton text={'Afrekenen'} onClick={handleLinkClick} />
              {/* <Cta text={"Afrekenen"} onClick={handleLinkClick} /> */}
            </Link>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <Typography tag={'h1'} type={'subtitle'}>
            Uw winkelwagen is leeg
          </Typography>
          <button onClick={handleLinkClick}>{t('Verder winkelen')}</button>
        </div>
      )}
    </>
  );
};

export default ShoppingCartAside;
