import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButton from '../../components/buttons/FormButton';
import LoadingSpinner from '../../components/buttons/LoadingSpinner';
import DateInput from '../../components/inputs/dates/DateInput';
import LicenseOverview from '../../components/licenses/LicenseOverview';
import { LicenseService } from '../../services/licenses/Licenses';
import { TicketService } from '../../services/tickets/Tickets';
import { clearAsides } from '../../state/component/AsideSlice';
import { clearLicenses } from '../../state/license/LicenseSlice';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';

const AddInternalLicense: FC = (license) => {
  const [loading, setLoading] = useState(true);
  const [otherLicenses, setOtherLicenses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const licenseService = new LicenseService();
  const ticketService = new TicketService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const selectedLicenses = useSelector(
    (state: RootState) => state.license.licenses
  );
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLicenses();

    return () => {
      dispatch(clearLicenses());
    };
  }, []);

  const fetchLicenses = async () => {
    setLoading(true);
    try {
      const [companyLicenses, allLicenses] = await Promise.all([
        licenseService.getLicenses(companyId),
        licenseService.getAllLicenses(companyId),
      ]);

      const other = allLicenses.filter(
        (lic) =>
          lic.lic_type !== 'Domein' &&
          !companyLicenses.some((compLic) => compLic.id_licentie === lic.id)
      );
      setOtherLicenses(other);
    } catch (error) {
      console.error('Error fetching licenses:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const filteredSelectedLicenses = Object.fromEntries(
      Object.entries(selectedLicenses).filter(
        ([id, { quantity }]) => quantity > 0
      )
    );

    const licenseDescriptions = Object.entries(filteredSelectedLicenses)
      .map(
        ([id, { name, quantity }]) =>
          `- ${name}: ${quantity} licentie${quantity > 1 ? 's' : ''}`
      )
      .join('\n');

    const formattedDate = selectedDate.format('DD-MM-YYYY');

    const description = `De volgende licenties moeten toegevoegd worden vanaf ${formattedDate}:\n${licenseDescriptions}`;

    if (Object.entries(filteredSelectedLicenses).length > 0) {
      try {
        dispatch(
          addToast({
            description: 'De licenties zijn succesvol aangevraagd.',
            position: 'bottomRight',
            style: 'success',
          })
        );

        dispatch(clearAsides());

        await ticketService.createTicketZendesk(companyId, {
          subject: 'Nieuwe licenties toevoegen',
          description: description,
        });
      } catch (error) {
        console.error('Error adding licenses:', error);
      }
    } else {
      dispatch(
        addToast({
          description: 'Selecteer een licentie.',
          position: 'bottomRight',
          style: 'error',
        })
      );
    }
  };

  return (
    <div className="overflow-hidden">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <LicenseOverview licenses={otherLicenses} />
          <div className="mt-3">
            <DateInput
              onChange={(date) => setSelectedDate(date)}
              value={selectedDate}
            />
          </div>
          <div className="mt-5">
            <FormButton text={'Licenties aanvragen'} onClick={handleSubmit} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddInternalLicense;
