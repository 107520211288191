import { Link } from 'gatsby';
import React from 'react';

type MenuItem = {
  key: string;
  icon: React.ReactNode;
  label: React.ReactNode;
  children?: MenuItem[];
  onClick?: () => void;
};

const MobileNav = ({
  menuOpen,
  setMenuOpen,
  menuItems,
}: {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  menuItems: MenuItem[];
}) => {
  return (
    menuOpen && (
      <div className="fixed inset-0 z-50 bg-blue-600 flex flex-col overflow-y-auto">
        <div className="flex flex-col flex-1">
          <nav className="flex flex-col px-4 py-10">
            {menuItems.map((item) => {
              const linkTo = item.label?.props?.to;

              const handleClick = () => {
                setMenuOpen(false);
                if (item.onClick) {
                  item.onClick();
                }
              };

              return (
                <div
                  key={item.key}
                  className="text-white text-lg w-full max-w-xs ml-5"
                >
                  {linkTo ? (
                    <Link
                      to={linkTo}
                      onClick={handleClick}
                      className="flex items-center space-x-2 py-3 px-4 rounded hover:bg-blue-700"
                    >
                      {item.icon}
                      <span className="text-xl">
                        {item.label.props.children}
                      </span>
                    </Link>
                  ) : (
                    <>
                      <div
                        onClick={handleClick}
                        className="flex items-center space-x-2 py-3 px-4 rounded hover:bg-blue-700 cursor-pointer"
                      >
                        {item.icon}
                        <span className="text-xl">{item.label}</span>
                      </div>
                      {item.children && (
                        <div>
                          {item.children.map((subItem) => {
                            const subLinkTo = subItem.label?.props?.to;

                            const handleSubClick = () => {
                              setMenuOpen(false);
                              if (subItem.onClick) {
                                subItem.onClick();
                              }
                            };

                            return subLinkTo ? (
                              <Link
                                key={subItem.key}
                                to={subLinkTo}
                                onClick={handleSubClick}
                                className="flex space-x-2 py-2 px-4 rounded hover:bg-blue-800 text-lg"
                              >
                                {subItem.icon}
                                <span>{subItem.label.props.children}</span>
                              </Link>
                            ) : (
                              <div
                                key={subItem.key}
                                onClick={handleSubClick}
                                className="flex space-x-2 py-2 px-4 ml-12 hover:bg-blue-800 text-lg cursor-pointer border-l border-white"
                              >
                                {subItem.icon}
                                <span>{subItem.label}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    )
  );
};

export default MobileNav;
