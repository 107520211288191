export const Capitalize = (text) => {
  // Check if the text is not empty
  if (text && text.length > 0) {
    // Capitalize the first character and concatenate with the rest of the string
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    // Return an empty string if the text is empty
    return '';
  }
};
