import { Collapse, CollapseProps, ConfigProvider } from 'antd';
import React from 'react';

const Accordeon = ({
  panels,
  activeKey,
}: {
  panels: any;
  activeKey?: number;
}) => {
  const items: CollapseProps['items'] = panels;
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: 'transparent',
            colorBgContainer: 'transparent',
          },
        },
      }}
    >
      <Collapse accordion items={items} defaultActiveKey={activeKey ?? 0} />
    </ConfigProvider>
  );
};

export default Accordeon;
