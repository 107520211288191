import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/buttons/LoadingSpinner';
import Table from '../../../components/tables/Table';
import TableItem from '../../../components/tables/TableItem';
import DateFormatted from '../../../components/typography/Date';
import Time from '../../../components/typography/Time';
import { AssetsService } from '../../../services/assets/Assets';
import { RootState } from '../../../state/store';
import { TruncateString } from '../../../utils/text/TruncateString';

const WindowsActivities = ({ assetId }) => {
  const [formLoading, setFormLoading] = useState(true);
  const [activitiesData, setActivitiesData] = useState();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new AssetsService();

  useEffect(() => {
    setFormLoading(true);

    const fetchData = async () => {
      const activitiesData = await service.fetchNinjaActivities(
        companyId,
        assetId
      );
      setActivitiesData(activitiesData.activities);
      setFormLoading(false);
    };

    fetchData();
  }, [assetId]);

  const tableHeaders: object = ['Status', 'Melding', 'Tijd'];

  return (
    <div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Table header={tableHeaders}>
            {activitiesData?.map((activity: any, index) => (
              <tr key={index}>
                <TableItem>{activity.status}</TableItem>
                <TableItem>
                  {<TruncateString text={activity.message} limit={30} />}
                </TableItem>
                <TableItem>
                  <div className="flex flex-col">
                    <DateFormatted date={activity.activityTime * 1000} />
                    <Time time={activity.activityTime * 1000} />
                  </div>
                </TableItem>
              </tr>
            ))}
          </Table>
        </>
      )}
    </div>
  );
};

export default WindowsActivities;
