import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsService } from '../../services/notification/Notifications';
import {
  hideNotifications,
  setNotificationCount,
} from '../../state/notification/NotificationSlice';
import { RootState } from '../../state/store';
import { Capitalize } from '../../utils/text/Capitalize';
import Tabs from '../tabs/Tabs';
import Typography from '../typography/Typography';
import NotificationList from './NotificationList';

const NotificationModal: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [activeTab, setActiveTab] = useState<string>('1');
  const notificationService = new NotificationsService();
  const notificationState = useSelector(
    (state: RootState) => state.notification
  );
  const dispatch = useDispatch();

  const user_id = useSelector((state: RootState) => state.profile.userId);
  const company_id: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );

  useEffect(() => {
    if (company_id) {
      fetchNotifications();
    }
  }, [company_id]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const notifications = await notificationService.getUserNotifications(
        user_id,
        company_id
      );
      setNotifications(notifications.result);
      const unreadNotifications = notifications.result.filter(
        (notification) => notification.is_read === 0
      );
      dispatch(setNotificationCount(unreadNotifications.length));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateNotificationState = (updatedNotification: Notification) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === updatedNotification.id
          ? updatedNotification
          : notification
      )
    );
  };

  const uniqueTypes = Array.from(
    new Set(notifications.map((notification) => notification.type))
  );

  const tabs = [
    {
      nav: t('All'),
      component: (
        <NotificationList
          notifications={notifications}
          onNotificationUpdate={updateNotificationState}
        />
      ),
    },
    ...uniqueTypes.map((type) => ({
      nav: t(Capitalize(type)),
      component: (
        <NotificationList
          notifications={notifications.filter(
            (notification) => notification.type === type
          )}
          onNotificationUpdate={updateNotificationState}
        />
      ),
    })),
  ];

  const setInvisible = () => {
    dispatch(hideNotifications());
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <>
      {!loading && (
        <div
          className={`fixed z-[100] top-12 h-full w-screen md:right-5 md:mt-4 md:w-96 md:h-auto bg-white dark:bg-slate-800 shadow-2xl md:rounded-lg overflow-hidden transition-all origin-top duration-300 ${
            notificationState.isVisible ? 'scale-100' : 'scale-0'
          }`}
        >
          <div className="bg-blue-700 text-white p-3 flex justify-between items-center">
            <Typography tag={'h2'} type={'title'} color="white">
              Notifications
            </Typography>
            <button
              className="text-sm text-gray-200 hover:text-white"
              onClick={setInvisible}
            >
              <X />
            </button>
          </div>
          <div>
            <Tabs
              tabs={tabs}
              operations={undefined}
              activeKey={activeTab}
              onChange={handleTabChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationModal;
