import { createSlice } from '@reduxjs/toolkit';

interface NotificationState {
  isVisible: boolean;
  sideBarOpen: boolean;
  notificationCount: number;
}

const initialState: NotificationState = {
  isVisible: false,
  sideBarOpen: false,
  notificationCount: 0,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotifications(state) {
      state.isVisible = true;
    },
    hideNotifications(state) {
      state.isVisible = false;
    },
    toggleNotifications(state) {
      state.isVisible = !state.isVisible;
    },
    setSideBarOpen(state, action) {
      state.sideBarOpen = action.payload;
    },
    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
    updateNotificationCount(state, action) {
      state.notificationCount += action.payload;
    },
  },
});

export const {
  showNotifications,
  hideNotifications,
  toggleNotifications,
  setSideBarOpen,
  setNotificationCount,
  updateNotificationCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
