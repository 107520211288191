import React, { FC } from 'react';
import EditUserForm from '../../../components/forms/users/EditUserForm';
import TextHeader from '../../../components/headers/TextHeader';

export interface AsideProps {
  data: {
    title: string;
  };
  callback?: (e: any) => void;
}

const UserCreateContainer: FC<AsideProps> = ({ data: { title }, callback }) => {
  console.log('UserCreateContainer', callback);
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Gebruiker" />
      <EditUserForm user={{}} title={title} callback={callback}></EditUserForm>
    </div>
  );
};

export default UserCreateContainer;
