import { Form } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButton from '../../components/buttons/FormButton';
import TextHeader from '../../components/headers/TextHeader';
import SelectBox from '../../components/inputs/selectbox/SelectBox';
import FormField from '../../components/inputs/text/FormField';
import { LicenseService } from '../../services/licenses/Licenses';
import { clearAsides } from '../../state/component/AsideSlice';
import { addCompanyLicense } from '../../state/license/LicenseSlice';
import { RootState } from '../../state/store';
import { addToast } from '../../state/toast/ToastSlice';

const AddThirdPartyLicense: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new LicenseService();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await service.getCategories(companyId);
        setCategories(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const onCreate = async (values: any) => {
    try {
      const license = {
        lic_licentie: values.name,
        lic_type: selectedCategory,
        aantal: 1,
        prijs: Number(values.price),
        periode: String(values.period),
      };
      const licenseId = await service.createLicenseComplete(companyId, license);

      dispatch(addCompanyLicense({ ...license, id: licenseId.data }));
      dispatch(
        addToast({
          description: 'De licentie is aangemaakt.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      closeAside();
    } catch (error) {
      console.error('Error creating license:', error);
    }
  };

  const closeAside = () => {
    dispatch(clearAsides());
  };

  const handleCategoryChange = (label: string, value: any) => {
    setSelectedCategory(value.label);
  };

  return (
    <div>
      <TextHeader title={'Licentie toevoegen'} />
      <Form labelAlign="left" layout="vertical" form={form} onFinish={onCreate}>
        <div className="mt-5 grid grid-cols-2 gap-x-5">
          <div>
            <FormField
              type="text"
              label="Naam"
              placeholder=""
              name="name"
              form={form}
              isRequired={true}
            />
          </div>
          <div>
            <FormField
              type="number"
              label="Periode (maanden)"
              placeholder=""
              name="period"
              form={form}
              isRequired={true}
            />
          </div>
          <div>
            <FormField
              type="number"
              label="Prijs"
              placeholder=""
              name="price"
              form={form}
            />
          </div>
          <div className="mt-auto pb-[24px]">
            <SelectBox
              options={categories.map((category) => ({
                label: category.category,
                value: category.id,
              }))}
              defaultValue={'Selecteer categorie'}
              onChange={handleCategoryChange}
              loading={loading}
            />
          </div>
          <div className="col-span-2 my-2">
            <FormButton form={true} text={'Toevoegen'} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddThirdPartyLicense;
