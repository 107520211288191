import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../LoadingSpinner';

interface ButtonProps {
  text: string;
  loading: boolean | undefined;
  style?: keyof typeof styles;
}

const styles = {
  default: 'bg-blue-700 py-2 px-4 text-white mt-3 hover:bg-blue-800',
  danger: 'bg-red-100 text-red-700 py-1 px-4',
};

const spinner = {
  default: '#fff',
  danger: '#b91c1c',
};

const SubmitButton: FC<ButtonProps> = ({
  text,
  loading,
  style = 'default',
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={`${styles[style]} inline-flex items-center justify-center transition-all rounded-xl hover:rounded-lg font-semibold
      }`}
      disabled={loading}
    >
      {!loading ? '' : <LoadingSpinner stroke={spinner[style]} />} {t(text)}
    </button>
  );
};

export default SubmitButton;
