import { Button, Form } from 'antd';
import { Link } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GroupsService } from '../../../services/groups/Groups';
import { setRootAside } from '../../../state/component/AsideSlice';
import { createGroup, updateGroup } from '../../../state/group/GroupSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import {
  CompanyModules,
  hasModule,
} from '../../../utils/auth/AuthorizationUtility';
import FormButton from '../../buttons/FormButton';
import LoadingSpinner from '../../buttons/LoadingSpinner';
import FormField from '../../inputs/text/FormField';
import ToggleSwitch from '../../inputs/toggle/ToggleSwitch';
import PopoverItem from '../../tags/PopoverQuestionMark';
import Typography from '../../typography/Typography';

type Group = {
  group: any;
};

const EditGroupForm: FC<Group> = ({ group }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [groupObject, setGroupObject] = useState(group);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const modules = useSelector((state: RootState) => state.auth.modules);
  const service = new GroupsService();
  const dispatch = useDispatch();

  useEffect(() => {
    if (group) {
      setGroupObject(group);
      form.setFieldsValue(group);
      setLoading(false);
    }
  }, [group, form]);

  const handleToggleChange = (name: string) => {
    const updatedGroup = { ...groupObject, [name]: !groupObject[name] };
    setGroupObject(updatedGroup);
    form.setFieldsValue({ [name]: !groupObject[name] });
  };

  const onSubmit = async (values: any) => {
    try {
      const parsedValues = {
        ...values,
        bedGr_bedrag: values.bedGr_bedrag
          ? parseFloat(values.bedGr_bedrag)
          : null,
        bedGr_periode: values.bedGr_periode
          ? parseInt(values.bedGr_periode, 10)
          : null,
      };

      const { bedGr_ready, bedGr_deliveryChangeable, bedGr_requirements } =
        groupObject;

      const updatedValues = {
        ...(bedGr_ready !== null && { bedGr_ready: !!bedGr_ready }),
        ...(bedGr_deliveryChangeable !== null && {
          bedGr_deliveryChangeable: !!bedGr_deliveryChangeable,
        }),
        ...(bedGr_requirements !== null && {
          bedGr_requirements: JSON.stringify(bedGr_requirements),
        }),
        ...parsedValues,
      };
      await service.updateGroup(companyId, group.bedGr_id, updatedValues);
      dispatch(
        updateGroup({
          id: group.bedGr_id,
          updatedData: { ...updatedValues, bedGr_requirements },
        })
      );
      dispatch(
        addToast({
          description: 'De groep is aangepast.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      closeAside();
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  const onCreate = async (values: any) => {
    try {
      const parsedValues = {
        ...values,
        bedGr_bedrag: values.bedGr_bedrag
          ? parseFloat(values.bedGr_bedrag)
          : null,
        bedGr_periode: values.bedGr_periode
          ? parseInt(values.bedGr_periode, 10)
          : null,
      };

      const updatedValues = {
        ...groupObject,
        ...parsedValues,
      };
      const newGroup = await service.createGroup(companyId, updatedValues);
      dispatch(createGroup({ ...updatedValues, bedGr_id: newGroup.groupId }));
      dispatch(
        addToast({
          description: 'De groep is aangemaakt.',
          position: 'bottomRight',
          style: 'success',
        })
      );
      closeAside();
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const closeAside = () => {
    dispatch(
      setRootAside({
        component: 'groupDetail',
        data: { group: group },
        title: 'Groep',
      })
    );
  };

  return (
    <div>
      <div className="flex items-center gap-4">
        {Object.keys(group).length === 0
          ? ''
          : hasModule(modules, CompanyModules.CYOD) && (
              <div className="flex items-center gap-3">
                <Typography tag={'span'} type={'semibold'}>
                  {`${group.productsCount || 0} producten`}
                </Typography>
                <Link
                  to={`/shop/cyod/categories/overview?group=${group.bedGr_id}`}
                >
                  <Button
                    className="bg-blue-700 text-white hover:bg-blue-800"
                    onClick={closeAside}
                  >
                    {t('Producten selecteren')}
                  </Button>
                </Link>
              </div>
            )}
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Form
          labelAlign="left"
          layout="vertical"
          form={form}
          initialValues={group}
          onFinish={Object.keys(group).length === 0 ? onCreate : onSubmit}
        >
          <div className="mt-5 grid grid-cols-3 gap-x-5">
            <div className="col-span-1">
              <FormField
                type="text"
                label="Naam"
                placeholder="Groep"
                name="bedGr_naam"
                form={form}
                isRequired={true}
              />
            </div>
            {hasModule(modules, CompanyModules.CYOD) && (
              <>
                <div className="col-span-1">
                  <FormField
                    type="number"
                    label="Coupon&nbsp;(euro)"
                    placeholder="0"
                    name="bedGr_bedrag"
                    form={form}
                  />
                </div>
                <div className="col-span-1">
                  <FormField
                    type="number"
                    label="Periode (jaar)"
                    placeholder="0"
                    name="bedGr_periode"
                    form={form}
                  />
                </div>
                <div className="col-span-3 my-1 flex">
                  <ToggleSwitch
                    label="Kant & Klaar"
                    checked={groupObject.bedGr_ready}
                    onChange={() => handleToggleChange('bedGr_ready')}
                    name="bedGr_ready"
                  />
                  <PopoverItem
                    description={
                      'Smartphones moeten een beschermhoesje en -glaasje hebben.'
                    }
                    title="Kant & Klaar"
                  ></PopoverItem>
                </div>
              </>
            )}
            <div className="col-span-3 my-1">
              <ToggleSwitch
                label="Leveradres wijzigbaar"
                checked={groupObject.bedGr_deliveryChangeable}
                onChange={() => handleToggleChange('bedGr_deliveryChangeable')}
                name="bedGr_deliveryChangeable"
              />
            </div>
            <div className="col-span-3 my-2">
              <FormButton
                form={true}
                text={Object.keys(group).length === 0 ? 'Aanmaken' : 'Opslaan'}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default EditGroupForm;
