import React, { FC } from 'react';

import CurrencyFormatter from '../../../utils/text/CurrencyFormatter';
import TooltipArrow from '../../boxes/Tooltip';
import Typography from '../../typography/Typography';

type Props = {
  price: number;
  title: string;
};

const TooltipMetricGroups: FC<Props> = ({ price, title }) => {
  return (
    <TooltipArrow>
      <div className="block">
        <Typography tag="span" type="default">
          {title}
        </Typography>
      </div>
      <Typography tag="span" type="subtitle">
        <CurrencyFormatter price={price} />
      </Typography>
    </TooltipArrow>
  );
};

export default TooltipMetricGroups;
