import React, { FC } from 'react';
import EditGroupForm from '../../../components/forms/groups/EditGroupForm';
import TextHeader from '../../../components/headers/TextHeader';
import Tabs from '../../../components/tabs/Tabs';
import GroupMembersContainer from './GroupMembersContainer';

export interface Props {
  data: {
    group: any;
  };
}

const GroupDetailContainer: FC<Props> = ({ data: { group } }) => {
  const tabs = [
    {
      nav: 'Bewerken',
      component: [<EditGroupForm group={group} />],
    },
    {
      nav: 'Gebruikers',
      component: [<GroupMembersContainer group={group} />],
    },
  ];
  return (
    <div>
      <TextHeader title={group.bedGr_naam} subtitle={'Groep'} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default GroupDetailContainer;
