import React, { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  type?: keyof typeof style;
  colspan?: number;
};

const style = {
  default: 'pl-6 py-3 whitespace-nowrap text-sm',
  stickyLeft:
    'pl-6 pr-3 py-3 whitespace-nowrap text-sm sticky left-0 z-10 bg-white dark:bg-gray-900 group-hover:bg-slate-50 dark:group-hover:bg-slate-600',
  stickyRight:
    'px-6 pr-3 py-3 whitespace-nowrap text-sm sticky right-0 z-10 bg-white dark:bg-gray-900 group-hover:bg-slate-50 dark:group-hover:bg-slate-600',
  center: 'pl-6 py-3 whitespace-nowrap text-sm flex justify-center',
  tiny: 'pl-6 py-1 whitespace-nowrap text-sm truncate',
  'tiny-np': 'whitespace-nowrap text-sm truncate',
};

const TableItem: FC<Props> = ({ children, type = 'default', colspan }) => {
  return (
    <td className={`${style[type]}`} colSpan={colspan}>
      {children}
    </td>
  );
};

export default TableItem;
