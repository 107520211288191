import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import SelectTarif from '../../../organisms/simcards/SelectTarif';
import Cta from '../../buttons/Cta';
import DateRange from '../../inputs/dates/DateRange';
import Typography from '../../typography/Typography';
import RatePlanInfo from '../../typography/sim/RatePlanInfo';
import Form from '../Form';

import { Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardInterface } from '../../../interfaces/Simcard';
import TarifRoaming from '../../../organisms/simcards/TarifRoaming';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TarifService } from '../../../services/simcards/Tarifs';
import { TicketService } from '../../../services/tickets/Tickets';
import { patchSimcard } from '../../../state/simdata/SimcardsSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';
import LoadingSpinner from '../../buttons/LoadingSpinner';
import { SimStatusTag } from '../../tags/StatusTag';

type props = {
  currentValue: string | undefined;
  simData: SimcardInterface;
  setSim: any;
};

const AddOption: FC<props> = ({ currentValue, simData, setSim }) => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [date, setDate] = useState([dayjs()]);
  const [tarif, setTarif] = useState();
  const [roaming, setRoaming] = useState(undefined);

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const tarifService = new TarifService();
  const dispatch = useDispatch();

  const handleChange = async (event: number) => {
    setRoaming(undefined);
    if (companyId) {
      setDataLoading(true);
      setTarif(event);

      const tarifDetail = await tarifService.getTarif(event, companyId);
      setRoaming(tarifDetail);
      setDataLoading(false);
    }
  };

  const handleDate = (date) => {
    setDate(date);
  };

  const removeOption = async () => {
    setLoading(true);
    await tarifService.removeOption(simData.sim_options[0].apt_id, companyId);

    setSim((state: SimcardInterface) => ({
      ...state,
      sim_options: [],
    }));

    dispatch(
      patchSimcard({ props: { sim_options: [] }, simId: simData.sim_id })
    );

    const description = `Opzeggen optie: ${simData.sim_options[0]?.trf_naam} voor simkaart ${simData.sim_unique_nr}`;

    setLoading(false);

    dispatch(
      addToast({
        description: [
          'Opzeggen optie',
          {
            trf_naam: simData.sim_options[0]?.trf_naam,
            sim_unique_nr: simData.sim_unique_nr,
          },
        ],
        position: 'bottomRight',
        style: 'success',
      })
    );

    ticketService.createTicketZendesk(companyId, {
      subject: `Opzeggen optie ${simData.sim_options[0]?.trf_naam}`,
      description: description,
      department: 'Telecom',
      linkedResource: {
        id: simData.sim_unique_nr,
        type: 'TELECOM',
      },
    });

    dispatch(
      addToast({
        description: 'Er is een ticket aangemaakt.',
        position: 'bottomRight',
        style: 'success',
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!date.length) {
      setLoading(false);
      return false;
    }

    if (!tarif) {
      setLoading(false);
      return false;
    }

    const body = {
      sim_id: simData.sim_id,
      trf_id: tarif,
      startDate: date[0].format('YYYY-MM-DD'),
      endDate: date.length > 1 ? date[1].format('YYYY-MM-DD') : null,
    };
    const option = await tarifService.addOption(body, companyId);
    const dates = {
      startDate: date[0].format('DD/MM/YYYY'),
      endDate: date.length > 1 ? date[1].format('DD/MM/YYYY') : '∞',
    };

    const prop = {
      sim_isActive: SimStatusTag.ADD_OPTION,
      sim_options: [option],
    };

    setSim((state: SimcardInterface) => ({
      ...state,
      ...prop,
    }));
    dispatch(patchSimcard({ props: prop, simId: simData.sim_id }));

    await simService.updateSimcard(
      simData.sim_id,
      { sim_isActive: SimStatusTag.ADD_OPTION },
      companyId
    );

    ticketService.createTicketZendesk(companyId, {
      subject: `Toevoegen optie ${option?.trf_naam}`,
      description: `Toevoegen optie: ${option?.trf_naam} voor simkaart ${simData.sim_unique_nr} vanaf ${dates.startDate} tot ${dates.endDate}`,
      department: 'Telecom',
      linkedResource: {
        id: simData.sim_unique_nr,
        type: 'TELECOM',
      },
    });

    setLoading(false);
  };

  return (
    <div>
      {!simData.sim_options || simData.sim_options.length === 0 ? (
        <Form
          buttonLabel="Indienen"
          handleSubmit={handleSubmit}
          gridSize="grid-cols-2"
          loading={loading}
        >
          <Typography tag="label" type="label">
            Selecteer een optie
          </Typography>
          <SelectTarif
            onSelect={handleChange}
            defaultValue={currentValue}
            type="option"
          />
          {dataLoading && (
            <div className="mt-2">
              <LoadingSpinner />
            </div>
          )}
          {tarif && <TarifRoaming roaming={roaming} />}
          <div className="block mt-2 mb-2">
            <Typography tag="label" type="label">
              Start en einddatum
            </Typography>
            <div className="block">
              <DateRange onChange={handleDate} value={date} />
            </div>
          </div>
        </Form>
      ) : (
        <>
          <p>{simData.sim_options[0]?.trf_naam}</p>
          <RatePlanInfo ratePlan={simData.sim_options[0]} />
          <div className="mt-4"></div>
          <Cta
            text="Opzeggen"
            loading={loading}
            style="danger"
            onClick={removeOption}
            icon={<Trash2 size={14} />}
          />
        </>
      )}
    </div>
  );
};

export default AddOption;
