import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedProduct {
  [id: number]: {
    category: string;
    product: CartItem;
    accessories: CartItem[];
  };
}

interface CyodState {
  selectedProducts: {
    [id: number]: {
      category: string;
      product: CartItem;
      accessories: CartItem[];
    };
  }; // CYOD before accessories
  cyodProducts: {
    [id: number]: {
      category: string;
      product: CartItem;
      accessories: CartItem[];
    };
  };
  coupon: number;
  usedCoupon: number;
}

const initialState: CyodState = {
  selectedProducts: {},
  cyodProducts: {},
  coupon: 0,
  usedCoupon: 0,
};

const cyodSlice = createSlice({
  name: 'cyod',
  initialState,
  reducers: {
    selectCyodProduct: (state, action: PayloadAction<SelectedProduct>) => {
      const productId = Object.keys(action.payload)[0]; // Assuming only one product is selected at a time
      const productData = Object.values(action.payload)[0]; // Assuming only one product is selected at a time

      return {
        ...state,
        selectedProducts: {
          ...state.selectedProducts,
          [parseInt(productId)]: productData, // Ensure productId is parsed as a number
        },
      };
    },
    selectAccessory: (
      state,
      action: PayloadAction<{ productId: number; accessory: CartItem }>
    ) => {
      const { productId, accessory } = action.payload;
      const selectedProduct = state.cyodProducts[productId];
      if (selectedProduct) {
        selectedProduct.accessories.push({ accessory });
      }
    },
    deselectAccessory: (
      state,
      action: PayloadAction<{ productId: number; accessoryId: number }>
    ) => {
      const { productId, accessoryId } = action.payload;
      const selectedProduct = state.cyodProducts[productId];
      if (selectedProduct) {
        selectedProduct.accessories = selectedProduct.accessories.filter(
          (item) => item.accessory.id !== accessoryId
        );
      }
    },
    addCyodProduct: (state, action: PayloadAction<SelectedProduct>) => {
      const productId = Object.keys(action.payload)[0]; // Assuming only one product is selected at a time
      const productData = Object.values(action.payload)[0]; // Assuming only one product is selected at a time
      return {
        ...state,
        cyodProducts: {
          // ...state.cyodProducts,
          [parseInt(productId)]: productData, // Ensure productId is parsed as a number
        },
      };
    },

    pushCyodProduct: (state) => {
      Object.keys(state.cyodProducts).forEach((productId) => {
        state.selectedProducts[productId] = state.cyodProducts[productId];
      });

      state.cyodProducts = {};
    },
    removeCyodProduct: (state, action: PayloadAction<number>) => {
      delete state.selectedProducts[action.payload];
      if (Object.values(state.selectedProducts).length === 0) {
        state.coupon = 0;
        state.usedCoupon = 0;
      }
    },
    addCyodCoupon: (state, action: PayloadAction<number>) => {
      state.coupon = action.payload;
    },
    addCyodUsedCoupon: (state, action: PayloadAction<number>) => {
      state.usedCoupon = action.payload;
    },
    clearCyodSelection: (state) => {
      state.selectedProducts = {};
      state.cyodProducts = {};
      state.coupon = 0;
      state.usedCoupon = 0;
    },
  },
});

export const {
  selectCyodProduct,
  selectAccessory,
  deselectAccessory,
  addCyodProduct,
  addCyodUsedCoupon,
  pushCyodProduct,
  removeCyodProduct,
  addCyodCoupon,
  clearCyodSelection,
} = cyodSlice.actions;

export default cyodSlice.reducer;
