import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimcardService } from '../../../services/simcards/Simcards';
import { TicketService } from '../../../services/tickets/Tickets';
import { clearAsides } from '../../../state/component/AsideSlice';
import { setCurrentProject } from '../../../state/project/ProjectSlice';
import { RootState } from '../../../state/store';
import { addToast } from '../../../state/toast/ToastSlice';

import SimOnboardingForm from '../../../components/forms/users/SimOnboardingForm';
import { UsersService } from '../../../services/users/Users';

const ActivateSimRequest: FC = () => {
  const [loading, setLoading] = useState(false);
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  ) as number;
  const simcardService = new SimcardService();
  const ticketService = new TicketService();

  const dispatch = useDispatch();

  const handleSubmit = async (formData) => {
    setLoading(true);

    try {
      await simcardService.updateOrCreateProximusSim(
        {
          sim_unique_nr:
            formData.get('isESim') === 'true'
              ? ''
              : formData.get('simcards[sim]'),
          sim_user_id: Number(formData.get('user_id')),
          sim_trf_id: Number(formData.get('simcards[tarifId]')),
          sim_status: 'Aanvraag activatie',
          sim_type: formData.get('isESim') === 'true' ? 'eSim' : 'Regular',
        },
        companyId
      );

      const user = await new UsersService().getUser(
        companyId,
        Number(formData.get('user_id'))
      );
      if (!user) {
        throw new Error('User not found');
      }

      const description = `
      <div>
        <h3>Aanvraag activatie SIM-kaart</h3>
        <p><strong>SIM-nummer:</strong> ${
          formData.get('isESim') === 'true'
            ? 'eSIM'
            : formData.get('simcards[sim]')
        }</p>
        <p><strong>Tariefplan:</strong> ${formData.get('simcards[tarif]')}</p>
        <p><strong>Status:</strong> Aanvraag activatie</p>
        <p><strong>Begindatum:</strong> ${formData.get('date')}</p>
        <p><string>Gebruiker:</strong> ${user.geb_voornaam} ${user.geb_naam}</p>
      </div>
    `;

      await ticketService.createTicketZendesk(companyId, {
        subject: `Activatie-aanvraag SIM-kaart`,
        description,
        department: 'Telecom',
        linkedResource:
          formData.get('isESim') !== 'true'
            ? {
                id: formData.get('simcards[sim]'),
                type: 'TELECOM',
              }
            : undefined,
      });

      dispatch(clearAsides());

      dispatch(
        addToast({
          description: 'De aanvraag is succesvol ingediend.',
          position: 'bottomRight',
          style: 'success',
        })
      );

      dispatch(setCurrentProject(project));
    } catch (error) {
      console.error('Error processing the request:', error);

      dispatch(
        addToast({
          description:
            'Er is een fout opgetreden bij het indienen van de aanvraag.',
          position: 'bottomRight',
          style: 'error',
        })
      );
    }

    setLoading(false);
  };

  return (
    <div>
      <SimOnboardingForm onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default ActivateSimRequest;
