import React, { FC } from 'react';
import { Clock } from 'react-feather';

type Time = {
  time: string;
  icon?: boolean;
};

const Time: FC<Time> = ({ time, icon }) => {
  let timeFormatted = new Date(time).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  let ico = icon ? <Clock size={14} /> : '';

  return (
    <span className="inline-flex items-center gap-1 text-xs font-bold normal-case font-regular">
      {ico} {timeFormatted}
    </span>
  );
};

export default Time;
