import apiClient from '../../../api-client';

export const GetDateMonthBack = async (companyId: number) => {
  try {
    const response = await apiClient.get(
      `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/metrics/latest`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      `Error fetching latest metrics: ${
        error.response?.statusText || error.message
      }`
    );
  }
};
