import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SimcardService } from '../../services/simcards/Simcards';

const service = new SimcardService();

interface Interface {
  loading: boolean;
  simcards: object;
  simcard: object;
  error: string | undefined;
}

const initialState: Interface = {
  loading: false,
  simcards: [],
  simcard: [],
  error: '',
};

export const fetchSimcards = createAsyncThunk(
  'simcards/fetchSimcards',
  (companyId: number) => {
    return service.getSimcards(companyId);
  }
);

export const fetchSimcard = createAsyncThunk(
  'simcards/fetchSimcard',
  (simId: number) => {
    return service.getSimcard(simId);
  }
);

const simcardSlice = createSlice({
  name: 'simcards',
  initialState: initialState,
  reducers: {
    patchSimcard(
      state,
      action: PayloadAction<{ props: object; simId: number }>
    ) {
      const sim = state.simcards.find((v) => v.sim_id == action.payload.simId);
      const prop = action.payload.props;

      Object.keys(prop).forEach((v) => {
        sim[v] = prop[v];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSimcards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSimcards.fulfilled,
      (state, action: PayloadAction<object>) => {
        state.loading = false;
        state.simcards = action.payload;
        state.error = state.error = '';
      }
    );
    builder.addCase(fetchSimcards.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { patchSimcard } = simcardSlice.actions;

export default simcardSlice.reducer;
